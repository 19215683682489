import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import CtaButton from '../../components/Buttons/CtaButton';
import SecondaryButton from '../../components/Buttons/SecondaryButton';

import { styled } from '@mui/system'
import { Typography } from '@mui/material';

import { getFirebase } from "../../firebase";

const SSectionTitle = styled(Typography)(({ theme }) => ({
  marginLeft: '0',
  marginBottom: '12px',
  [theme.breakpoints.up('md')]: {
    marginLeft: '24px',
    marginBottom: '24px',
  }
}));

const SOptionTitle = styled(Typography)(({ theme }) => ({
  marginLeft: '0',
  [theme.breakpoints.up('md')]: {
    marginLeft: '24px',
  }
}));

const SResultBar = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  paddingLeft: '16px',
  transition: 'width 1s',
  borderRadius: '5px',
}));

const SButtonGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  justifyContent: 'flex-end',
  marginBottom: '16px',
  [theme.breakpoints.up('md')]: {
    maxWidth: '192px',
  }
}));

const STopTextGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between',
  }
}));

function ResultsView({
  isMobile,
  decision,
  handleBackClick,
}) {
  const [resultTotals, setResultTotals] = useState([]);
  const [maxScore, setMaxScore] = useState(0);

  useEffect(() => {
    let scoreArrays = decision.scores;
    let results = [];
    let currentTotal = 0;
    let max = 0;

    for (let i = 0; i < decision.options.length; i++) {
      currentTotal = 0;
      decision.criteria.forEach((item, criteriaIndex) => {
        currentTotal += scoreArrays[i][criteriaIndex] * item.weight;
      });
      results.push([currentTotal, i]);
      if (currentTotal > max) {
        max = currentTotal;
      }
    }

    results.sort(function (a, b) {
      return b[0] - a[0];
    });

    setResultTotals(results);
    setMaxScore(max);
  }, []);

  const handleMyDecisionClick = () => {
    window.location.href = '/my-decisions';
  }

  return (
    <>
      <STopTextGrid container item>
        <Grid container item xs={12} md={8} pr={isMobile ? 0 : 3} order={isMobile ? 2 : 1}>
          <Typography variant='h1'>{`New Decision: ${decision.question}`}</Typography>
        </Grid>
        <SButtonGrid container item xs={12} md={4} order={isMobile ? 1 : 2}>
          <CtaButton text='My Decisions' handleClick={handleMyDecisionClick} />
        </SButtonGrid>
      </STopTextGrid>
      <Grid container item mt={3} mb={3}>
        <SSectionTitle variant='h2'>Results (larger is better)</SSectionTitle>
      </Grid>
      <Grid container item mb={8}>
        {
          resultTotals.map((result, index) => (
            <Stack key={`resultsOption${index}`} direction='row' mb={3} sx={{ width: '100%' }}>
              <Grid container item xs={3} mr={1} alignItems='center'>
                <SOptionTitle variant='h6'>{decision.options[result[1]]}</SOptionTitle>
              </Grid>
              <Grid container item xs={9}>
                <SResultBar
                  item
                  alignItems='center'
                  py={1}
                  sx={{
                    width: resultTotals.length === 0 ? '0' : `${Math.ceil((result[0] / maxScore) * 100)}%`,
                  }}
                >
                  <Typography variant='caption' sx={{ color: 'secondary.contrastText' }}>{result[0]}</Typography>
                </SResultBar>
              </Grid>
            </Stack>
          ))
        }
      </Grid>
      <Stack direction='row' sx={{ width: '100%' }}>
        <Grid sx={{ minWidth: { xs: '0', md: '135px' } }}>
          <SecondaryButton text='Back' handleClick={handleBackClick} />
        </Grid>
      </Stack>
    </>
  );
}

export default ResultsView;