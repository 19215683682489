import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/system'
import { Typography } from '@mui/material';

const SButton = styled(Button)(({ theme, color }) => ({
  width: '100%',
  height: '52px',
  textTransform: 'none',
  borderRadius: '5px',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: `${color}.dark`,
    boxShadow: 'none',
  },
  [theme.breakpoints.down('md')]: {
    height: '42px',
    width: 'auto',
  }
}));

function CtaButton({ isDisabled, text, color, fullWidth, isModal, handleClick }) {

  return (
    <SButton
      variant='contained'
      disabled={isDisabled !== null ? isDisabled : false}
      color={color || 'primary'}
      sx={{
        ...(fullWidth && { width: '100%!important' }),
        ...(isDisabled && { cursor: 'default!important' }),
      }}
      onClick={handleClick}
    >
      <Typography variant='button'>{text}</Typography>
    </SButton>
  )
}

export default CtaButton;