import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system'

import AppleIcon from '../../static/img/social-icons/apple-icon.svg'
import GoogleIcon from '../../static/img/social-icons/google-icon.svg'
import FacebookIcon from '../../static/img/social-icons/facebook-icon.svg'
import TwitterIcon from '../../static/img/social-icons/twitter-icon.svg'

const SButton = styled(Button)({
  width: '100%',
  height: '52px',
  backgroundColor: '#FFF',
  textTransform: 'none',
  fontWeight: '700',
  borderRadius: '5px',
  boxShadow: 'none',
  position: 'relative',

  '&:hover': {
    backgroundColor: '#E7E7E7',
    boxShadow: 'none',
  }
});

const SButtonText = styled(Typography)({
  fontSize: '1.2rem',
  color: '#A9A9A9',
  fontWeight: '700',
});

function SocialButton({ isDisabled, text, handleClick }) {

  const getIcon = (text) => {
    let socialIcon = ''

    switch (text) {
      case 'Google':
        socialIcon = GoogleIcon
        break
      case 'Apple':
        socialIcon = AppleIcon
        break
      case 'Facebook':
        socialIcon = FacebookIcon
        break
      default:
        socialIcon = TwitterIcon
        break

    }

    return socialIcon;
  }

  return (
    <SButton 
      variant='contained'
      disabled={isDisabled !== null ? isDisabled : false}
      onClick={handleClick}
    >
      <img src={getIcon(text)} alt={`Icon of the ${text} logo`} style={{ position: 'absolute', left: '20px' }} />
      <SButtonText>{text}</SButtonText>
    </SButton>
  )
}

export default SocialButton;