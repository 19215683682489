export const config = {
  // firebase: {
  //   apiKey: "AIzaSyCB5rNvWEZIE80CkNsiHQt47cYJKJc8BgY",
  //   authDomain: "smart-decision-1308d.web.app",
  //   projectId: "smart-decision-1308d",
  //   storageBucket: "smart-decision-1308d.appspot.com",
  //   messagingSenderId: "588165020873",
  //   appId: "1:588165020873:web:dd605f1ce94a2d49b02118",
  //   measurementId: "G-HFWHHVMCQY"
  // }
  firebase: {
    apiKey: "AIzaSyCB5rNvWEZIE80CkNsiHQt47cYJKJc8BgY",
    authDomain: "smartdecision.app",
    projectId: "smart-decision-1308d",
    storageBucket: "smart-decision-1308d.appspot.com",
    messagingSenderId: "588165020873",
    appId: "1:588165020873:web:dd605f1ce94a2d49b02118",
    measurementId: "G-HFWHHVMCQY"
  }
}