import React from 'react';
import Link from '@mui/material/Link';
import { styled } from '@mui/system'

const SLink = styled(Link)(() => ({
  textDecoration: 'none'
}));

const StyledLink = ({ href, text }) => {
  return (
    <SLink href={href}>
      {text}
    </SLink>
  )
}

export default StyledLink;