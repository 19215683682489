import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import CtaButton from '../../components/Buttons/CtaButton';
import SecondaryButton from '../../components/Buttons/SecondaryButton';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import Rating from '@mui/material/Rating';
import CircularProgress from '@mui/material/CircularProgress';
import GeneralModal from '../../components/Modals/GeneralModal';

import DeleteIcon from '../../static/img/decision-icons/delete-x-icon.svg';
import CreateIcon from '../../static/img/decision-icons/create-icon.svg';
import {
  FREE_TIER_OPTIONS_LIMIT,
  FREE_TIER_CRITERIA_LIMIT
} from '../../utils/constants';

const SSectionTitle = styled(Typography)(({ theme }) => ({
  marginLeft: '0',
  marginBottom: '12px',
  [theme.breakpoints.up('md')]: {
    marginLeft: '24px',
    marginBottom: '24px',
  }
}));

const SCriteriaSectionTitleGrid = styled(Grid)(({ theme }) => ({
  width: 'calc(100% - 154px)',
  [theme.breakpoints.up('md')]: {
    width: 'calc(100% - 194px)',
  }
}));

const STitle = styled(Typography)({
  marginBottom: '36px',
});

const STextField = styled(TextField)({
  backgroundColor: '#FFF',
  width: 'calc(100% - 50px)'
});

const SRatingHeading = styled(Typography)({
  paddingBottom: '8px'
});

const SDeleteIcon = styled('img')(({ theme }) => ({
  width: '20px',
  height: '20px',
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    width: '30px',
    height: '30px',
  }
}));

function QuestionView({
  isMobile,
  decision,
  isLoading,
  createCriteria,
  createOption,
  deleteCriteria,
  deleteOption,
  handleBackClick,
  handleScoreClick,
  onInputChange,
}) {
  const [focusOption, setFocusOption] = useState(false);
  const [focusCriteria, setFocusCriteria] = useState(false);
  const [showUpgradeModalOptions, setShowUpgradeModalOptions] = useState(false)
  const [showUpgradeModalCriteria, setShowUpgradeModalCriteria] = useState(false)

  const handleCriteriaAddClick = () => {
    if (decision.criteria.length < FREE_TIER_CRITERIA_LIMIT) {
      createCriteria();
      setFocusCriteria(true);
    } else {
      setShowUpgradeModalCriteria(true)
    }
  }

  const handleOptionAddClick = () => {
    // if on the free tier only allow option creation if under the limit
    if (decision.options.length < FREE_TIER_OPTIONS_LIMIT) {
      createOption();
      setFocusOption(true);
    } else {
      setShowUpgradeModalOptions(true)
    }
  }

  const areAllFieldsValid = () => {
    const checkCriteria = () => {
      return decision.criteria.every((criterion) => {
        return (criterion.weight > 0 && criterion.text !== '')
      })
    }

    const checkOptions = () => {
      return decision.options.every((option) => {
        return option && option !== ''
      })
    }

    const checkQuestion = () => {
      return decision.question && decision.question !== '';
    }

    return (checkCriteria() && checkOptions() && checkQuestion());
  }

  const navigatePage = (link) => {
    window.location.href = link;
  }

  const handleUpgradeModalOptionsClose = () => {
    setShowUpgradeModalOptions(false)
  }

  const handleUpgradeModalCriteriaClose = () => {
    setShowUpgradeModalCriteria(false)
  }

  return (
    <>
      <Grid container item justifyContent='space-between'>
        <STitle variant='h1'>New Decision</STitle>
      </Grid>
      {
        isLoading && (
          <Grid container item justifyContent='center' pt={10}>
            <CircularProgress />
          </Grid>
        )
      }
      {
        !isLoading && (
          <>
            <GeneralModal
              showModal={showUpgradeModalOptions}
              handleDialogClose={handleUpgradeModalOptionsClose}
              message={`You cannot create more than ${FREE_TIER_OPTIONS_LIMIT} options on the free tier. Please upgrade to add more options.`}
              secondaryText='Cancel'
              handleSecondaryButtonClick={handleUpgradeModalOptionsClose}
              ctaText='Upgrade'
              handleCtaClick={() => navigatePage('/features')}
            />
            <GeneralModal
              showModal={showUpgradeModalCriteria}
              handleDialogClose={handleUpgradeModalCriteriaClose}
              message={`You cannot create more than ${FREE_TIER_CRITERIA_LIMIT} criteria on the free tier. Please upgrade to add more criteria.`}
              secondaryText='Cancel'
              handleSecondaryButtonClick={handleUpgradeModalCriteriaClose}
              ctaText='Upgrade'
              handleCtaClick={() => navigatePage('/features')}
            />
            <Grid container item>
              <Stack sx={{ width: '100%', marginBottom: '24px' }}>
                <SSectionTitle variant='h2'>Enter your question</SSectionTitle>
                <STextField
                  label=""
                  placeholder='e.g., What car should I buy?'
                  type='question'
                  value={decision.question}
                  onChange={(e) => onInputChange(e, 'question')}
                />
              </Stack>
              <Stack sx={{ width: { xs: '100%', md: '50%' }, marginBottom: '24px' }}>
                <SSectionTitle variant='h2'>Enter your options</SSectionTitle>
                {
                  decision.options.map((option, i) => (
                    <Stack key={`option${i}`} direction="row" spacing={2} alignItems='center' sx={{ marginBottom: '16px' }}>
                      <STextField
                        key={`newOption${i}`}
                        autoFocus={focusOption && (i === (decision.options.length - 1))}
                        label=""
                        placeholder='e.g., Tesla Model 3'
                        type='options'
                        value={option}
                        onChange={(e) => onInputChange(e, 'options', i)}
                      />
                      <SDeleteIcon src={DeleteIcon} onClick={(e) => deleteOption(e, i)} />
                    </Stack>
                  ))
                }
                {/* {
                  decision.options.length < FREE_TIER_OPTIONS_LIMIT && ( */}
                <img src={CreateIcon} alt='Create option button icon' style={{ height: '30px', width: '30px', cursor: 'pointer' }} onClick={handleOptionAddClick} />
                {/* )
                } */}
              </Stack>
              <Stack sx={{ width: { xs: '100%', md: '70%' }, marginBottom: '24px' }}>
                <Grid container item alignItems='flex-end'>
                  <SCriteriaSectionTitleGrid>
                    <SSectionTitle variant='h2'>Enter your decision criteria</SSectionTitle>
                  </SCriteriaSectionTitleGrid>
                  <SRatingHeading variant='h6'>Importance</SRatingHeading>
                </Grid>
                {
                  decision.criteria.map((criteria, i) => (
                    <Stack key={`criteria${i}`} direction="row" spacing={2} alignItems='center' sx={{ marginBottom: '16px' }}>
                      <STextField
                        key={`newCriteria${i}`}
                        autoFocus={focusCriteria && (i === (decision.criteria.length - 1))}
                        sx={{ marginBottom: '0' }}
                        label=""
                        placeholder='e.g., Cost'
                        type='criteria'
                        value={criteria.text}
                        onChange={(e) => onInputChange(e, 'criteriaText', i)}
                      />
                      <Grid item justifyContent='center'>
                        <Rating
                          name="simple-controlled"
                          size={isMobile ? 'medium' : 'large'}
                          value={Number(criteria.weight)}
                          onChange={(e) => onInputChange(e, 'criteriaWeight', i)}
                        />
                      </Grid>
                      <SDeleteIcon src={DeleteIcon} onClick={(e) => deleteCriteria(e, i)} />
                    </Stack>
                  ))
                }
                {/* {
                  decision.criteria.length < FREE_TIER_CRITERIA_LIMIT && ( */}
                <img src={CreateIcon} alt='Create criteria button icon' style={{ height: '30px', width: '30px', cursor: 'pointer' }} onClick={handleCriteriaAddClick} />
                {/* )
                } */}
              </Stack>
            </Grid>
            <Stack direction='row' sx={{ width: '100%' }}>
              <Grid mr={2} sx={{ minWidth: { xs: '0', md: '135px' } }}>
                <SecondaryButton text='Back' handleClick={handleBackClick} />
              </Grid>
              <Grid sx={{ minWidth: { xs: '0', sm: '260px' } }}>
                <CtaButton isDisabled={!areAllFieldsValid()} text='Score your options' handleClick={handleScoreClick} />
              </Grid>
            </Stack>
          </>
        )
      }
    </>
  );
}

export default QuestionView;