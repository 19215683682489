import React, { useEffect, useState, Fragment } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import ListItemIcon from '@mui/material/ListItemIcon';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/system';

import { MAX_CONTENT_WIDTH } from '../utils/constants'
import useIsMobile from '../hooks/useIsMobile';

import {
  onAuthStateChanged,
  signOut
} from '@firebase/auth';

import { getFirebase } from "../firebase";
import { collection, query, where, getDocs } from 'firebase/firestore';

import Logo from '../static/img/smart-decision-logo.svg';
// import Logo from '../static/img/sd_logo.svg';
import AddIcon from '@mui/icons-material/Add';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListIcon from '@mui/icons-material/List';
import SupportIcon from '@mui/icons-material/Support';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import FeaturesIcon from '@mui/icons-material/MonetizationOn';

const SLink = styled(Link)({
  textDecoration: 'none'
});

const SNavButton = styled(Button)(({ iscta }) => ({
  borderBottom: '2px solid transparent',
  borderBottomLeftRadius: iscta === 'true' ? '4px' : '0',
  borderBottomRightRadius: iscta === 'true' ? '4px' : '0',
  display: 'block',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: iscta === 'true' ? '#2D0898' : 'transparent',
    borderColor: iscta === 'true' ? 'transparent' : '#410CDA'
  }
}));

const SLogoTypography = styled(Typography)({
  display: { xs: 'none', md: 'flex' },
  fontWeight: 700,
  letterSpacing: '.3rem',
  color: '#410CDA',
  textDecoration: 'none',
})

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const { firestore, auth } = getFirebase();
  const usersCol = collection(firestore, 'users');

  const loggedOutPages = [
    { title: 'Features', link: '/features', listIcon: <FeaturesIcon fontSize='medium' /> },
    { title: 'Support', link: '/support', listIcon: <SupportIcon fontSize='medium' /> },
    { title: 'Sign In', link: '/sign-in', listIcon: <LoginIcon fontSize='medium' /> },
    { title: 'Sign Up for Free', link: '/sign-up', listIcon: <AppRegistrationIcon fontSize='medium' /> }
  ];

  const loggedInPages = [
    { title: 'Features', link: '/features', listIcon: <FeaturesIcon fontSize='medium' /> },
    { title: 'Support', link: '/support', listIcon: <SupportIcon fontSize='medium' /> },
    { title: 'My Decisions', link: '/my-decisions', listIcon: <ListIcon fontSize='medium' /> },
    { title: 'New Decision', link: '/new-decision', listIcon: <AddIcon fontSize='medium' /> },
    { title: 'Log Out', link: '#', listIcon: <LogoutIcon fontSize='medium' /> },
  ];

  const loggedInPagesMobile = [
    { title: 'Features', link: '/features', listIcon: <FeaturesIcon fontSize='medium' /> },
    { title: 'Support', link: '/support', listIcon: <SupportIcon fontSize='medium' /> },
    { title: 'My Decisions', link: '/my-decisions', listIcon: <ListIcon fontSize='medium' /> },
    { title: 'New Decision', link: '/new-decision', listIcon: <AddIcon fontSize='medium' /> },
    { title: 'My Profile', link: '/my-profile', listIcon: <AccountCircleIcon fontSize='medium' /> },
    { title: 'Log Out', link: '#', listIcon: <LogoutIcon fontSize='medium' /> },
  ];

  const accountMenu = [
    { title: 'My Profile', link: '/my-profile', listIcon: <AccountCircleIcon fontSize='medium' /> },
    { title: 'Log Out', link: '#', listIcon: <LogoutIcon fontSize='medium' /> },
  ];

  const getCurrentPage = (path) => {
    // let path = window.location.pathname;
    let noLeadingSlashPath = path;
    let noSlashPath = '';

    if (path && path.length > 0 && path[0] === '/') {
      noLeadingSlashPath = path.slice(1);
    }

    noSlashPath = noLeadingSlashPath.split('/')[0];
    return noSlashPath;
  }

  const [pages, setPages] = useState(loggedOutPages);
  const [activeTab, setActiveTab] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(getCurrentPage(window.location.pathname));
  const isMobile = useIsMobile();

  useEffect(() => {
    const path = getCurrentPage(window.location.pathname);
    setCurrentPage(path);

  }, [window.location.pathname])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoggedIn(true);
        const usersQuery = query(usersCol, where('uid', '==', user.uid));
        getDocs(usersQuery).then((snap) => {
          if (!snap.empty) {
            const docs = snap.docs.map(doc => doc.data())
            setUser(docs[0]);
          } else {
            setUser(null)
          }
        }).catch((error) => {
          console.error(error);
          // setShowErrorModal({
          //   error: true,
          //   ctaText: 'Support',
          // })
        });
      } else {
        setUser(null);
        setIsLoggedIn(false)
      }
    });

    return () => {
      unsubscribe();
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      setPages(isMobile ? loggedInPagesMobile : loggedInPages)
    } else {
      setPages(loggedOutPages);
    }

  }, [isMobile, isLoggedIn])

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (title) => {
    window.sessionStorage.removeItem("currentDecision");
    setActiveTab(title);
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    window.sessionStorage.removeItem("currentDecision");
    setAnchorElUser(null);
  };

  const handleNavButtonClick = async (e, title) => {
    if (title === 'Log Out') {
      e.preventDefault();
      signOut(auth).then(() => {
        window.sessionStorage.removeItem("currentDecision");
        window.location.href = '/';
      }).catch(() => {
        console.error('There was a problem logging the user out');
      })
    }
  }

  const isCtaLink = (title) => {
    return (title === "Sign Up for Free" || title === "Log Out")
  }

  return (
    <header className="App-header" style={{ margin: '20px auto 0', maxWidth: MAX_CONTENT_WIDTH, width: '100%' }}>
      <AppBar position="static" elevation={0} sx={{ backgroundColor: '#FFF' }}>
        <Container maxWidth={false} sx={{ backgroundColor: '#FFF' }}>
          <Toolbar disableGutters>
            <Box sx={{ padding: '10px 0' }}>
              <Link href="/" color="inherit">
                <img src={Logo} alt='Smart Decision App' />
              </Link>
            </Box>
            <Badge badgeContent='BETA' color='primary'>
              <Stack ml={2} onClick={() => window.location.href = '/'} sx={{ cursor: 'pointer' }}>
                <SLogoTypography variant="h6">Smart</SLogoTypography>
                <SLogoTypography variant="h6">Decision</SLogoTypography>
              </Stack>
            </Badge>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon sx={{ color: "#000" }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {
                  pages.map((page, index) => {
                    return (
                      <MenuItem key={`mobileNav${page.title}${index}`} onClick={handleCloseNavMenu}>
                        <SLink href={page.link} onClick={((e) => handleNavButtonClick(e, page.title))}>
                          <Stack direction='row' alignItems='center'>
                            {page.listIcon && (
                              <ListItemIcon
                                sx={{
                                  color: page.link === `/${currentPage}` ? 'primary.main' : 'inheret',
                                }}
                              >
                                {page.listIcon}
                              </ListItemIcon>
                            )}
                            <Typography
                              variant='h4'
                              sx={{
                                color: page.link === `/${currentPage}` ? 'primary.main' : 'inheret',
                                fontWeight: page.link === `/${currentPage}` ? '500' : 'inheret',
                              }}
                            >
                              {page.title}
                            </Typography>
                          </Stack>
                        </SLink>
                      </MenuItem>
                    )
                  })
                }
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', alignItems: 'center', display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page, index) => {
                const isLast = (i) => pages.length - 1 === i;

                if (!isLast(index) || (isLast && !isLoggedIn)) {
                  return (
                    <SNavButton
                      key={`nav${page.title}${index}`}
                      iscta={isCtaLink(page.title).toString()}
                      onClick={() => handleCloseNavMenu(page.title)}
                      sx={{
                        backgroundColor: isLast(index) ? '#410CDA' : null,
                        marginLeft: isLast(index) ? '10px' : '0',
                        color: activeTab === page.title && !isCtaLink(page.title) ? '#410CDA' : 'inherit',
                      }}
                    >
                      <SLink href={page.link} onClick={((e) => handleNavButtonClick(e, page.title))}>
                        <Typography
                          variant='h4'
                          sx={{
                            color: isLast(index) ? '#fff' : page.link === `/${currentPage}` ? 'primary.main' : 'inheret',
                            fontWeight: page.link === `/${currentPage}` ? '500' : 'inheret',
                          }}
                        >
                          {page.title}
                        </Typography>
                      </SLink>
                    </SNavButton>
                  )
                } else if (isLast(index) && isLoggedIn) {
                  return (
                    <Fragment key={`nav${page.title}${index}`}>
                      <Grid
                        item
                        container
                        sx={{
                          backgroundColor: user?.avatarColor,
                          width: '30px',
                          height: '30px',
                          borderRadius: '50%',
                          cursor: 'pointer',
                        }}
                        ml={1}
                        alignItems='center'
                        justifyContent='center'
                        onClick={handleOpenUserMenu}
                      >
                        <Typography sx={{ fontSize: '20px', fontWeight: '500', color: 'primary.contrastText' }}>{user?.displayName?.slice(0, 1).toUpperCase()}</Typography>
                      </Grid>
                      <Menu
                        id='menu-userBar'
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                        sx={{
                          display: { xs: 'none', md: 'block' },
                        }}
                      >
                        {
                          accountMenu.map((page, index) => {
                            return (
                              <MenuItem key={`loggedInNav${page.title}${index}`} onClick={handleCloseUserMenu}>
                                <SLink href={page.link} onClick={((e) => handleNavButtonClick(e, page.title))}>
                                  <Stack direction='row' alignItems='center'>
                                    {page.listIcon && (
                                      <ListItemIcon
                                        sx={{
                                          color: page.link === `/${currentPage}` ? 'primary.main' : 'inheret',
                                        }}
                                      >
                                        {page.listIcon}
                                      </ListItemIcon>
                                    )}
                                    <Typography
                                      variant='h4'
                                      sx={{
                                        color: page.link === `/${currentPage}` ? 'primary.main' : 'inheret',
                                        fontWeight: page.link === `/${currentPage}` ? '500' : 'inheret',
                                      }}
                                    >
                                      {page.title}
                                    </Typography>
                                  </Stack>
                                </SLink>
                              </MenuItem>
                            )
                          })
                        }
                      </Menu>
                    </Fragment>
                  )
                }
              }
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </header>
  );
}

export default ResponsiveAppBar;