import React from 'react';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system'

const SButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '52px',
  textTransform: 'none',
  borderRadius: '5px',
  boxShadow: 'none',
  [theme.breakpoints.down('md')]: {
    height: '42px',
    width: 'auto',
  }
}));

const STypography = styled(Typography)(({ theme, isModal, textColor }) => ({
  color: theme.palette[textColor].main,
  ...(isModal && { fontSize: '0.875rem' }),
}));

function SecondaryButton({ text, color, isDisabled, isModal, handleClick }) {

  return (
    <SButton
      variant='outlined'
      disabled={isDisabled !== null ? isDisabled : false}
      onClick={handleClick}
      color={color || 'primary'}
    >
      <STypography variant='button' textColor={color || 'primary'} isModal={isModal}>{text}</STypography>
    </SButton>
  )
}

export default SecondaryButton;