import React from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system'
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import DeleteIcon from '../../static/img/decision-icons/delete-x-icon.svg';

const SDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: '24px 0 0',
}));

const SCloseIcon = styled('img')(({ theme }) => ({
  width: '24px',
  height: '24px',
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    width: '30px',
    height: '30px',
  }
}));

function InfoModal({
  showModal,
  handleDialogClose,
  message,
}) {
  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="general-dialog-title"
      open={showModal}
    >
      <SDialogTitle id="general-dialog-title" onClose={handleDialogClose} />
      <DialogContent>
        <Grid container item justifyContent='flex-end' mb={4}>
          <SCloseIcon src={DeleteIcon} alt='Close icon for dialog' onClick={handleDialogClose} />
        </Grid>
        <Typography variant='body1' mb={3}>{message}</Typography>
      </DialogContent>
    </Dialog>
  )
}

export default InfoModal;