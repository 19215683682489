import React from 'react';
import { styled } from '@mui/system'
import { Grid, Link, Typography } from '@mui/material';
import MainContentSection from '../components/Sections/MainContentSection';

const SBodySpan = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const SBodyTypography = styled(Typography)(({ type }) => ({
  marginBottom: type === 'body1' ? '16px' : '32px',
}));

const STitleSpan = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const EULA = () => {

  return (
    <MainContentSection>
      <Grid container item mb={4}>
        <Typography variant='h1' mb={4}>End-User License Agreement (EULA) of <STitleSpan>Smart Decision</STitleSpan></Typography>
        <Grid container item mb={2}>
          <SBodyTypography variant='body1' type='body1'>This End-User License Agreement ("EULA") is a legal agreement between you and <SBodySpan>Plausible Future LLC</SBodySpan>.</SBodyTypography>
          <SBodyTypography variant='body1' type='body1'>This EULA agreement governs your acquisition and use of our <SBodySpan>Smart Decision</SBodySpan> software ("Software") directly from <SBodySpan class="company_name">Plausible Future LLC</SBodySpan> or indirectly through a <SBodySpan class="company_name">Plausible Future LLC</SBodySpan> authorized reseller or distributor (a "Reseller"). </SBodyTypography>
          <SBodyTypography variant='body1' type='body1'>Please read this EULA agreement carefully before completing the installation process and using the <SBodySpan class="app_name">Smart Decision</SBodySpan> software. It provides a license to use the <SBodySpan>Smart Decision</SBodySpan> software and contains warranty information and liability disclaimers.</SBodyTypography>
          <SBodyTypography variant='body1' type='body1'>If you register for a free trial of the <SBodySpan class="app_name">Smart Decision</SBodySpan> software, this EULA agreement will also govern that trial. By clicking "accept" or installing and/or using the <SBodySpan>Smart Decision</SBodySpan> software, you are confirming your acceptance of the Software and agreeing to become bound by the terms of this EULA agreement.</SBodyTypography>
          <SBodyTypography variant='body1' type='body1'>If you are entering into this EULA agreement on behalf of a company or other legal entity, you represent that you have the authority to bind such entity and its affiliates to these terms and conditions. If you do not have such authority or if you do not agree with the terms and conditions of this EULA agreement, do not install or use the Software, and you must not accept this EULA agreement.</SBodyTypography>
          <SBodyTypography variant='body1' type='body1'>This EULA agreement shall apply only to the Software supplied by <SBodySpan class="company_name">Plausible Future LLC</SBodySpan> herewith regardless of whether other software is referred to or described herein. The terms also apply to any <SBodySpan class="company_name">Plausible Future LLC</SBodySpan> updates, supplements, Internet-based services, and support services for the Software, unless other terms accompany those items on delivery. If so, those terms apply.</SBodyTypography>
        </Grid>
        <Grid container item mb={2}>
          <SBodyTypography variant='h3' type='h3'>License Grant</SBodyTypography>
          <SBodyTypography variant='body1' type='body1'><SBodySpan class="company_name">Plausible Future LLC</SBodySpan> hereby grants you a personal, non-transferable, non-exclusive licence to use the <SBodySpan class="app_name">Smart Decision</SBodySpan> software on your devices in accordance with the terms of this EULA agreement.</SBodyTypography>
          <SBodyTypography variant='body1' type='body1'>You are permitted to load the <SBodySpan class="app_name">Smart Decision</SBodySpan> software (for example a PC, laptop, mobile or tablet) under your control. You are responsible for ensuring your device meets the minimum requirements of the <SBodySpan class="app_name">Smart Decision</SBodySpan> software.</SBodyTypography>
          <SBodyTypography variant='body1' type='body1'>You are not permitted to:</SBodyTypography>
          <ul>
            <li><SBodyTypography variant='body1' type='body1'>Edit, alter, modify, adapt, translate or otherwise change the whole or any part of the Software nor permit the whole or any part of the Software to be combined with or become incorporated in any other software, nor decompile, disassemble or reverse engineer the Software or attempt to do any such things</SBodyTypography></li>
            <li><SBodyTypography variant='body1' type='body1'>Reproduce, copy, distribute, resell or otherwise use the Software for any commercial purpose</SBodyTypography></li>
            <li><SBodyTypography variant='body1' type='body1'>Allow any third party to use the Software on behalf of or for the benefit of any third party</SBodyTypography></li>
            <li><SBodyTypography variant='body1' type='body1'>Use the Software in any way which breaches any applicable local, national or international law</SBodyTypography></li>
            <li><SBodyTypography variant='body1' type='body1'>Use the Software for any purpose that <SBodySpan class="company_name">Plausible Future LLC</SBodySpan> considers is a breach of this EULA agreement</SBodyTypography></li>
          </ul>
        </Grid>
        <Grid container item mb={2}>
          <SBodyTypography variant='h3' type='h3'>Intellectual Property and Ownership</SBodyTypography>
          <SBodyTypography variant='body1' type='body1'><SBodySpan class="company_name">Plausible Future LLC</SBodySpan> shall at all times retain ownership of the Software as originally downloaded by you and all subsequent downloads of the Software by you. The Software (and the copyright, and other intellectual property rights of whatever nature in the Software, including any modifications made thereto) are and shall remain the property of <SBodySpan class="company_name">Plausible Future LLC</SBodySpan>.</SBodyTypography>
          <SBodyTypography variant='body1' type='body1'><SBodySpan class="company_name">Plausible Future LLC</SBodySpan> reserves the right to grant licences to use the Software to third parties.</SBodyTypography>
        </Grid>
        <Grid container item mb={2}>
          <SBodyTypography variant='h3' type='h3'>Termination</SBodyTypography>
          <SBodyTypography variant='body1' type='body1'>This EULA agreement is effective from the date you first use the Software and shall continue until terminated. You may terminate it at any time upon written notice to <SBodySpan class="company_name">Plausible Future LLC</SBodySpan>.</SBodyTypography>
          <SBodyTypography variant='body1' type='body1'>It will also terminate immediately if you fail to comply with any term of this EULA agreement. Upon such termination, the licenses granted by this EULA agreement will immediately terminate and you agree to stop all access and use of the Software. The provisions that by their nature continue and survive will survive any termination of this EULA agreement.</SBodyTypography>
        </Grid>
        <Grid container item mb={2}>
          <SBodyTypography variant='h3' type='h3'>Governing Law</SBodyTypography>
          <SBodyTypography variant='body1' type='body1'>This EULA agreement, and any dispute arising out of or in connection with this EULA agreement, shall be governed by and construed in accordance with the laws of the United States.</SBodyTypography>
        </Grid>
      </Grid>
    </MainContentSection>
  )
}

export default EULA;