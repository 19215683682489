import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import CtaButton from '../components/Buttons/CtaButton'
import SecondaryButton from '../components/Buttons/SecondaryButton'
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system'

import { SNACKBAR_INTERVAL } from '../utils/constants';

import TitleCtaHeading from '../components/Sections/TitleCtaHeading';
import MainContentSection from '../components/Sections/MainContentSection';
import InputModal from '../components/Modals/InputModal';

import validateEmailAddress from '../utils/inputValidation/validateEmailAddress';
import validatePassword from '../utils/inputValidation/validatePassword';

import { getFirebase } from '../firebase'
import {
  onAuthStateChanged,
  updateEmail,
  updatePassword,
  deleteUser,
  signOut
} from '@firebase/auth';

import { collection, query, where, getDocs, setDoc, deleteDoc } from 'firebase/firestore';

const SSectionTitle = styled(Typography)(({ theme }) => ({
  marginLeft: '0',
  marginBottom: '16px',
  [theme.breakpoints.up('md')]: {
    marginLeft: '24px',
    marginBottom: '24px',
  }
}));

const STextField = styled(TextField)({
  backgroundColor: '#FFF',
  width: 'calc(100% - 50px)'
});

function Profile() {
  const { firestore, auth } = getFirebase();
  const usersCol = collection(firestore, 'users');
  const decisionsCol = collection(firestore, 'decisions');
  const [email, setEmail] = useState({ value: '', isValid: false });
  const [password, setPassword] = useState({ value: '', isValid: false });
  const [confirmPassword, setConfirmPassword] = useState({ value: '', isValid: false });
  const [deleteAccount, setDeleteAccount] = useState({ value: '', isValid: false });
  const [currentUser, setCurrentUser] = useState(auth?.currentUser || null);
  const [openEmailSnackbar, setOpenEmailSnackbar] = useState(false);
  const [openPasswordSnackbar, setOpenPasswordSnackbar] = useState(false);
  const [openDeleteUserSnackbar, setOpenDeleteUserSnackbar] = useState(false);
  const [showUserDeletionConfirmationModal, setShowUserDeletionConfirmationModal] = useState(false);
  const [confirmDeleteAccount, setConfirmDeleteAccount] = useState({ value: '', isValid: false });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        window.location.href = '/sign-in';
      }
    });

    return () => {
      unsubscribe();
    }
  }, [])

  const handleNewDecisionClick = () => {
    window.location.href = '/new-decision';
  }

  const onInputChange = (e, type) => {
    const inputValue = e.target.value;
    let isValid = false;

    if (type === 'email') {
      if (validateEmailAddress(inputValue)) {
        isValid = true;
      }
      setEmail({ value: inputValue, isValid: isValid });
    } else if (type === 'password') {
      if (validatePassword(inputValue)) {
        isValid = true;
      }
      setPassword({ value: inputValue, isValid: isValid })
    } else if (type === 'confirmPassword') {
      if (validatePassword(inputValue) && (inputValue === password.value)) {
        isValid = true;
      }
      setConfirmPassword({ value: inputValue, isValid: isValid });
    } else if (type === 'deleteAccount') {
      if (inputValue && inputValue === 'Delete my account') {
        isValid = true;
      }
      setDeleteAccount({ value: inputValue, isValid: isValid });
    } else if (type === 'confirmDeleteAccount') {
      if (inputValue && inputValue === 'Delete My Account') {
        isValid = true;
      }
      setConfirmDeleteAccount({ value: inputValue, isValid: isValid });
    }
  }

  const handleChangeEmailClick = () => {
    updateEmail(auth.currentUser, email.value).then(() => {
      setOpenEmailSnackbar(true);
      setEmail({ value: '', isValid: false });
      const usersQuery = query(usersCol, where('uid', '==', auth.currentUser.uid));
      getDocs(usersQuery).then((snap) => {
        snap.forEach((doc) => {
          setDoc(doc.ref, { email: email.value, displayName: email.value.split('@')[0] }, { merge: true }).catch((error) => {
            console.error('Error updating email in userDoc: ', error)
          });
        })
      }).catch((error) => {
        console.error('Error while getting docs in Profile: ', error)
        // setShowErrorModal({
        //   error: true,
        //   ctaText: 'Support',
        // })
      });
    }).catch((error) => {
      console.error('Could not update email: ', error)
    });
  }

  const handleChangePasswordClick = () => {
    updatePassword(auth.currentUser, confirmPassword.value).then(() => {
      setOpenPasswordSnackbar(true);
      setPassword({ value: '', isValid: false });
      setConfirmPassword({ value: '', isValid: false });
    }).catch((error) => {
      console.error('Could not update password: ', error)
    });
  }

  const handleDeleteAccountClick = () => {
    setShowUserDeletionConfirmationModal(true);
  }

  const handleSnackBarClose = (event, reason, type) => {
    if (reason === 'clickaway') {
      return;
    }

    if (type === 'email') {
      setOpenEmailSnackbar(false);
    } else if (type === 'password') {
      setOpenPasswordSnackbar(false);
    } else if (type === 'deleteUser') {
      setOpenDeleteUserSnackbar(false);
    }
  }

  const emailSnackBarAction = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={(event, reason) => handleSnackBarClose(event, reason, 'email')}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const passwordSnackBarAction = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={(event, reason) => handleSnackBarClose(event, reason, 'password')}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const deleteUserSnackBarAction = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={(event, reason) => handleSnackBarClose(event, reason, 'deleteUser')}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const handleUserDeletionClose = () => {
    setShowUserDeletionConfirmationModal(false);
    setConfirmDeleteAccount({ value: '', isValid: false });
  }

  const handleDialogCtaClick = async () => {
    setShowUserDeletionConfirmationModal(false);
    const deletedUser = auth.currentUser

    try {
      const usersQuery = query(usersCol, where('uid', '==', deletedUser.uid));
      const decisionsQuery = query(decisionsCol, where('uid', '==', deletedUser.uid));
      let snapUsers = await getDocs(usersQuery);

      let snapDecisions = await getDocs(decisionsQuery);

      const deleteUserData = async (users, decisions) => {
        for (const docUser of users.docs) {
          await deleteDoc(docUser.ref).catch((error) => {
            console.error('Error deleting user in userDoc: ', error);
          })
        }

        for (const docDecision of decisions.docs) {
          await deleteDoc(docDecision.ref).catch((error) => {
            console.error('Error deleting decision of the deleted user in decisionDoc: ', error);
          })
        }
      }

      deleteUserData(snapUsers, snapDecisions)
        .then(() => {
          deleteUser(deletedUser).then(async () => {
            setOpenDeleteUserSnackbar(true);
            signOut(auth).then(() => {
              window.sessionStorage.removeItem("currentDecision");
              window.location.href = '/';
            }).catch((error) => {
              console.error('Error logging out the user in Profile');
            })
          })
        })
        .catch((error) => console.error('Error deleting user data: ', error));
    } catch (error) {
      console.error('Error deleting user data in Profile: ', error);
      // setShowErrorModal({
      //   error: true,
      //   ctaText: 'Support',
      // })
    }
  }

  return (
    <MainContentSection>

      <InputModal
        showModal={showUserDeletionConfirmationModal}
        handleDialogClose={handleUserDeletionClose}
        message={
          <>
            Are you absolutely sure you want to delete your account? Deleting your account cannot be reversed. All of your data will
            be deleted and cannot be restored. Confirm by typing <span style={{ fontWeight: '700' }}>Delete My Account</span> in the field below.
          </>
        }
        ctaText='Delete Account'
        secondaryText='Cancel'
        inputValue={confirmDeleteAccount}
        buttonColor='error'
        inputChangeType='confirmDeleteAccount'
        placeholderText='Type "Delete My Account" here'
        handleCtaClick={handleDialogCtaClick}
        handleSecondaryButtonClick={handleUserDeletionClose}
        onInputChange={onInputChange}
      />

      <Snackbar
        open={openEmailSnackbar}
        autoHideDuration={SNACKBAR_INTERVAL}
        onClose={(event, reason) => handleSnackBarClose(event, reason, 'email')}
        message='Email updated successfully!'
        action={emailSnackBarAction}
      />
      <Snackbar
        open={openPasswordSnackbar}
        autoHideDuration={SNACKBAR_INTERVAL}
        onClose={(event, reason) => handleSnackBarClose(event, reason, 'password')}
        message='Password updated successfully!'
        action={passwordSnackBarAction}
      />
      <Snackbar
        open={openDeleteUserSnackbar}
        autoHideDuration={SNACKBAR_INTERVAL}
        onClose={(event, reason) => handleSnackBarClose(event, reason, 'deleteUser')}
        message='User deleted successfully!'
        action={deleteUserSnackBarAction}
      />
      {
        !auth?.currentUser && (
          <Grid container item justifyContent='center' pt={10}>
            <CircularProgress />
          </Grid>
        )
      }
      {
        auth?.currentUser && (
          <>
            <Grid container item mb={{ xs: 4, sm: 7 }}>
              <TitleCtaHeading
                title='My Profile'
                ctaText='New Decision'
                ctaClick={handleNewDecisionClick}
              />
            </Grid>
            {
              currentUser?.providerData?.filter((item) => item.providerId !== 'password').length === 0 && (
                <>
                  <Grid container item>
                    <Stack sx={{ width: { xs: '100%', md: '50%' }, marginBottom: '48px' }}>
                      <SSectionTitle variant='h2'>Email</SSectionTitle>
                      <STextField
                        label=""
                        placeholder={currentUser?.email || ''}
                        type='email'
                        value={email.value}
                        onChange={(e) => onInputChange(e, 'email')}
                      />
                      <Grid container item xs={4} sx={{ minWidth: '275px' }} mt={2}>
                        <CtaButton isDisabled={!email.isValid} text='Change Email' handleClick={handleChangeEmailClick} />
                      </Grid>
                    </Stack>
                  </Grid>
                  <Grid container item>
                    <Stack sx={{ width: { xs: '100%', md: '50%' }, marginBottom: '48px' }}>
                      <SSectionTitle variant='h2'>Password</SSectionTitle>
                      <STextField
                        label=""
                        placeholder='Enter new password'
                        type='password'
                        value={password.value}
                        onChange={(e) => onInputChange(e, 'password')}
                        sx={{ marginBottom: '8px' }}
                      />
                      <STextField
                        label=""
                        placeholder='Confirm password'
                        type='password'
                        value={confirmPassword.value}
                        onChange={(e) => onInputChange(e, 'confirmPassword')}
                      />
                      <Grid container item xs={4} sx={{ minWidth: '275px' }} mt={2}>
                        <CtaButton
                          isDisabled={!(password.isValid && confirmPassword.isValid)}
                          text='Change Password' handleClick={handleChangePasswordClick}
                        />
                      </Grid>
                    </Stack>
                  </Grid>
                </>
              )
            }
            <Grid container item>
              <Stack sx={{ width: { xs: '100%', md: '50%' }, marginBottom: '72px' }}>
                <SSectionTitle variant='h2'>Delete Account</SSectionTitle>
                {/* <STextField
                  label=""
                  placeholder='Type "Delete my account" here'
                  type='text'
                  value={deleteAccount.value}
                  onChange={(e) => onInputChange(e, 'deleteAccount')}
                /> */}
                <Grid container item xs={4} sx={{ minWidth: '275px' }}>
                  <SecondaryButton text='Delete Account' handleClick={handleDeleteAccountClick} />
                </Grid>
              </Stack>
            </Grid>
          </>
        )
      }
    </MainContentSection>
  );
}

export default Profile;