import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CtaButton from '../../components/Buttons/CtaButton';
import SecondaryButton from '../../components/Buttons/SecondaryButton';

import { styled } from '@mui/system'
import { Typography } from '@mui/material';

const SSectionTitle = styled(Typography)(({ theme }) => ({
  marginLeft: '0',
  [theme.breakpoints.up('md')]: {
    marginLeft: '24px',
    display: 'inline-block',
  }
}));

const SSectionOptionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginLeft: '0',
  marginTop: '8px',
  [theme.breakpoints.up('md')]: {
    marginLeft: '24px',
    display: 'inline-block',
  }
}));

const STitleStack = styled(Typography)(({ theme }) => ({
  width: '100%',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  }
}));

const STitle = styled(Typography)({
  marginBottom: '36px',
});

function ScoringView({
  decision,
  handleBackClick,
  handleViewResultsClick,
  onInputChange,
}) {
  const [areScoresValid, setAreScoresValid] = useState(false);
  const criteriaList = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  useEffect(() => {
    let isValid = false;

    if (decision?.scores && decision.scores.length > 0) {
      isValid = decision?.scores?.every((scoreArray) => scoreArray.every((score) => score > 0));
    }

    setAreScoresValid(isValid);
  }, [decision.scores])

  return (
    <>
      <Grid container item justifyContent='space-between' mb={1}>
        <STitle variant='h1'>New Decision: {decision.question}</STitle>
      </Grid>
      {
        decision.options.map((option, indexOption) => (
          <Grid container item key={`scoringOptions${indexOption}`} mb={4}>
            <STitleStack alignItems='center' mb={3}>
              <SSectionTitle variant='h2'>Score each criterion for:</SSectionTitle>
              <SSectionOptionTitle variant='h1' component='h2'>{option}</SSectionOptionTitle>
            </STitleStack>
            <Stack
              direction='row'
              sx={{ width: '100%' }}
              mb={2}
              pl={{ xs: 0, md: 3 }}
            >
              <Grid container item xs={4} />
              <Grid container item xs={8}>
                <Grid container item justifyContent='space-between'>
                  <Typography variant='h6'>Poor</Typography>
                  <Typography variant='h6'>Excellent</Typography>
                </Grid>
              </Grid>
            </Stack>
            {
              decision.criteria.map((criteria, indexCriteria) => (
                <Stack
                  key={`scoringCriteria${option}${indexOption}${criteria.text}${indexCriteria}`}
                  direction='row'
                  sx={{ width: '100%' }}
                  mb={2}
                  pl={{ xs: 0, md: 3 }}
                >
                  <Grid container item xs={4} alignItems='center'>
                    <Typography variant='body1'>{criteria.text}</Typography>
                  </Grid>
                  <Grid container item xs={8} alignItems='center'>
                    <Grid container item justifyContent='space-between'>
                      {
                        criteriaList.map((listItem, i) => (
                          <Typography
                            variant='body1'
                            onClick={(e) => onInputChange(e, 'score', indexOption, indexCriteria, listItem)}
                            key={`scoringCriteriaItem${listItem}${option}${indexOption}${criteria.text}${indexCriteria}`}
                            sx={{
                              cursor: 'pointer',
                              padding: { xs: '2px 6px', md: '6px 12px' },
                              borderRadius: '50%',
                              border: `solid 1px ${decision.scores[indexOption][indexCriteria] === listItem ? '#410CDA' : 'transparent'}`,
                            }}
                          >
                            {listItem}
                          </Typography>
                        ))
                      }
                    </Grid>
                  </Grid>
                </Stack>
              ))
            }
          </Grid>
        ))
      }
      <Stack direction='row' sx={{ width: '100%' }} mt={3}>
        <Grid mr={2} sx={{ minWidth: { xs: '0', md: '135px' } }}>
          <SecondaryButton text='Back' handleClick={handleBackClick} />
        </Grid>
        <Grid sx={{ minWidth: { xs: '0', sm: '260px' } }}>
          <CtaButton text='View Results' isDisabled={!areScoresValid} handleClick={handleViewResultsClick} />
        </Grid>
      </Stack>
    </>
  );
}

export default ScoringView;