import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import CtaButton from '../components/Buttons/CtaButton'
import SocialButton from '../components/Buttons/SocialButton'
import { styled } from '@mui/system'
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import StyledLink from '../components/Links/StyledLink';

import MainContentSection from '../components/Sections/MainContentSection';
import ErrorModal from '../components/Modals/ErrorModal';

import validateEmailAddress from '../utils/inputValidation/validateEmailAddress';
import validatePassword from '../utils/inputValidation/validatePassword';

import { getFirebase } from '../firebase'
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  linkWithRedirect,
  GoogleAuthProvider,
  getRedirectResult,
  signInWithRedirect
} from '@firebase/auth';

import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';

const SCheckboxGrid = styled(Grid)({
  width: '33px'
});

const SCheckboxLabel = styled(Grid)({
  width: 'calc(100% - 33px)'
});

const SStack = styled(Stack)({
  width: '100%',
  backgroundColor: '#F0F0F0',
  padding: '30px',
  borderRadius: '15px',
});

const STextField = styled(TextField)({
  backgroundColor: '#FFF',
});

const SLineGrid = styled(Grid)({
  height: '1px',
  width: '100%',
  backgroundColor: '#A9A9A9',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
});

function SignUp() {
  const { firestore, auth } = getFirebase();
  const [email, setEmail] = useState({ value: '', isValid: false });
  const [password, setPassword] = useState({ value: '', isValid: false });
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const usersCol = collection(firestore, 'users');

  const socialList = ['Google'];

  const generateColor = () => {
    return `#${(Math.random().toString(16).substr(-6))}`;
  };

  // Sign up new user
  const createAccount = (event) => {
    if (email.isValid && password.isValid) {
      setIsLoading(true);
      createUserWithEmailAndPassword(auth, email.value, password.value).then((response) => {
        addDoc(usersCol, {
          uid: response.user.uid,
          displayName: response.user.email.split('@')[0],
          avatarColor: generateColor(),
          email: response.user.email,
        }).then(() => {
          window.location.href = '/my-decisions';
        }).catch(error => {
          setIsLoading(false);
          setShowErrorModal({
            error: true,
            ctaText: 'Support',
          })
        })
      }).catch((error) => {
        setIsLoading(false);
        if (error.code === 'auth/email-already-in-use') {
          setShowErrorModal({
            error: true,
            code: 'auth/email-already-in-use',
            ctaText: 'Sign In',
            message: 'Sorry, that email already exists. Please sign up with a different email address or sign in.',
          })
        } else {
          setShowErrorModal({
            error: true,
            ctaText: 'Support',
          })
        }
      })
    }
  }

  // Social media sign in
  useEffect(() => {
    try {
      getRedirectResult(auth).then((response) => {
        if (response?.operationType === 'signIn') {
          setIsLoading(true);
          const usersQuery = query(usersCol, where('uid', '==', response.user.uid));
          getDocs(usersQuery).then((snap) => {
            if (snap.empty) {
              addDoc(usersCol, {
                uid: response.user.uid,
                displayName: response.user.displayName,
                avatarColor: generateColor(),
                email: response.user.email,
              }).then(() => {
                window.location.href = '/my-decisions';
              }).catch(error => {
                setIsLoading(false);
                setShowErrorModal({
                  error: true,
                  ctaText: 'Support',
                })
              })
            } else {
              window.location.href = '/my-decisions';
            }
          }).catch((error) => {
            setIsLoading(false);
            console.error('Error while getting docs in Signup: ', error)
            setShowErrorModal({
              error: true,
              ctaText: 'Support',
            })
          });
        }
      })
    } catch (error) {
      setIsLoading(false);
      console.error('Error on redirect result: ', error);
      setShowErrorModal({
        error: true,
        ctaText: 'Support',
      })
    }

    const unsubscribe = onAuthStateChanged(auth, (userCurrent) => {
      // if (userCurrent) {
      //   addDoc(usersCol, {
      //     uid: userCurrent.uid,
      //     displayName: userCurrent.email.split('@')[0],
      //     avatarColor: generateColor(),
      //   });
      //   window.location.href = '/my-decisions';
      // }
      // setUser(user)
    });

    return () => {
      unsubscribe();
    }
  }, [])

  const onInputChange = (event, type) => {
    if (type === 'email') {
      setEmail({ value: event.target.value, isValid: validateEmailAddress(event.target.value) });
    } else if (type === 'password') {
      setPassword({ value: event.target.value, isValid: validatePassword(event.target.value) });
    }
  }

  const signInGoogle = () => {
    let provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    signInWithRedirect(auth, provider).catch((error) => {
      setIsLoading(false);
      console.error('error signing in user: ', error);
    });
  }

  const linkWithGoogle = () => {
    linkWithRedirect(auth.currentUser, new GoogleAuthProvider())
  }

  const handleSocialClick = async (social) => {
    switch (social) {
      case 'Google':
        signInGoogle();
        break;
    }
  }

  const handleDialogClose = () => {
    setShowErrorModal({
      error: false,
    })
  }

  const handleDialogCtaClick = () => {
    setShowErrorModal({
      error: false,
    })
    if (showErrorModal.code === 'auth/email-already-in-use') {
      window.location.href = '/sign-in';
    } else {
      window.location.href = '/support';
    }
  }

  const [EULAChecked, setEULAChecked] = useState(false);

  const handleEULACheckboxClick = () => {
    setEULAChecked(!EULAChecked)
  }

  return (
    <MainContentSection maximumWidth='480px'>

      <ErrorModal
        showModal={showErrorModal.error}
        handleDialogClose={handleDialogClose}
        message={showErrorModal.message}
        ctaText={showErrorModal.ctaText}
        handleCtaClick={handleDialogCtaClick}
      />

      {
        isLoading && (
          <Grid container item justifyContent='center' pt={10}>
            <CircularProgress />
          </Grid>
        )
      }
      {
        !isLoading && (
          <SStack>
            <STextField
              label=""
              placeholder='Email'
              type='email'
              value={email.value}
              onChange={(e) => onInputChange(e, 'email')}
            />
            <STextField
              label=""
              placeholder='Password'
              type='password'
              value={password.value}
              onChange={(e) => onInputChange(e, 'password')}
              sx={{ marginTop: '24px' }}
            />
            <Grid container item xs={12} mt={3} mb={1}>
              <CtaButton text='Sign Up for Free' fullWidth={true} handleClick={createAccount} isDisabled={!(email.isValid && password.isValid && EULAChecked)} sx={{ marginTop: '24px' }} />
            </Grid>
            <Grid container item mb={2}>
              <SCheckboxGrid container item>
                <Checkbox
                  checked={EULAChecked}
                  onChange={handleEULACheckboxClick}
                  inputProps={{ 'aria-label': 'controlled' }}
                  style={{ paddingLeft: '0' }}
                />
              </SCheckboxGrid>
              <SCheckboxLabel container item alignItems='center'>
                <Typography variant='subtitle1'>I agree to the <StyledLink href='/eula' text='End User License Agreement' />.</Typography>
              </SCheckboxLabel>
            </Grid>
            <Grid container item justifyContent='center' sx={{ position: 'relative' }}>
              <SLineGrid container item />
              <Typography sx={{ color: '#A9A9A9', backgroundColor: '#F0F0F0', zIndex: '10', padding: '0 24px' }}>Sign up with</Typography>
            </Grid>
            {
              socialList.map((item, index) => (
                <Grid key={`socialListSignUp${index}`} container item xs={12} mt={3}>
                  <SocialButton
                    isDisabled={!EULAChecked}
                    text={item}
                    handleClick={() => handleSocialClick(item)}
                  />
                </Grid>
              ))
            }
          </SStack>
        )
      }
    </MainContentSection>
  );
}

export default SignUp;
