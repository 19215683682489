import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import CtaButton from '../components/Buttons/CtaButton';
import SocialButton from '../components/Buttons/SocialButton'
import { styled } from '@mui/system'
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import MainContentSection from '../components/Sections/MainContentSection';
import InputModal from '../components/Modals/InputModal';
import InfoModal from '../components/Modals/InfoModal';
import ErrorModal from '../components/Modals/ErrorModal';
import StyledLink from '../components/Links/StyledLink';

import validateEmailAddress from '../utils/inputValidation/validateEmailAddress';
import validatePassword from '../utils/inputValidation/validatePassword';

import { getFirebase } from "../firebase";
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';

import {
  onAuthStateChanged,
  GoogleAuthProvider,
  getRedirectResult,
  signInWithRedirect,
  signInWithEmailAndPassword,
  sendPasswordResetEmail
} from '@firebase/auth';

const SStack = styled(Stack)({
  width: '100%',
  padding: '30px',
  backgroundColor: '#F0F0F0',
  borderRadius: '15px',
});

const STextField = styled(TextField)({
  backgroundColor: '#FFF',
});

const SLineGrid = styled(Grid)({
  height: '1px',
  width: '100%',
  backgroundColor: '#A9A9A9',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
});

const SLinkText = styled(Typography)(({ theme }) => ({
  color: theme.palette.link.main,
}));

function SignIn() {
  const { firestore, auth } = getFirebase();
  const [email, setEmail] = useState({ value: '', isValid: false });
  const [resetEmail, setResetEmail] = useState({ value: '', isValid: false });
  const [password, setPassword] = useState({ value: '', isValid: false });
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showResetConfirmationModal, setShowResetConfirmationModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const usersCol = collection(firestore, 'users');

  const socialList = ['Google'];

  const generateColor = () => {
    return `#${(Math.random().toString(16).substr(-6))}`;
  };

  const onInputChange = (event, type) => {
    if (type === 'email') {
      setEmail({ value: event.target.value, isValid: validateEmailAddress(event.target.value) });
    } else if (type === 'password') {
      setPassword({ value: event.target.value, isValid: validatePassword(event.target.value) });
    } else if (type === 'resetEmail') {
      setResetEmail({ value: event.target.value, isValid: validateEmailAddress(event.target.value) });
    }
  }

  const signInGoogle = () => {
    let provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    signInWithRedirect(auth, provider).catch((error) => {
      setIsLoading(false);
      console.error('error signing in user: ', error);
    });
  }

  const handleSocialClick = (social) => {
    switch (social) {
      case 'Google':
        signInGoogle();
        break;
    }
  }

  const handleDialogClose = () => {
    setShowErrorModal({
      error: false,
    })
  }

  const handleResetDialogClose = () => {
    setShowResetConfirmationModal(false);
  }

  const handleEmailDialogClose = () => {
    setShowEmailModal(false);
  }

  const handleDialogCtaClick = () => {
    setShowErrorModal({
      error: false,
    })
    window.location.href = '/support';
  }

  // Sign up new user
  const signInEmailPassword = (event) => {
    if (email.isValid && password.isValid) {
      setIsLoading(true);
      signInWithEmailAndPassword(auth, email.value, password.value)
        .then((response) => {
          if (response.user) {
            window.location.href = '/my-decisions';
          }
        }).catch((error) => {
          setIsLoading(false);
          if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
            setShowErrorModal({
              error: true,
              code: 'auth/user-not-found',
              message: 'Sorry, your email or password is incorrect. Please try again.',
            });
          } else {
            setShowErrorModal({
              error: true,
              ctaText: 'Support',
            })
          }
        })
    }
  }

  const handleForgotPasswordClick = () => {
    setShowEmailModal(true);
  }

  const handleEmailResetModalClick = () => {
    setShowEmailModal(false);
    sendPasswordResetEmail(auth, resetEmail.value)
      .then((response) => {
        // Password reset email sent!
        setShowResetConfirmationModal(true);
        setResetEmail({ value: '', isValid: false });
      })
      .catch((error) => {
        console.error('Error sending reset email: ', error.code)
        setShowErrorModal({
          error: true,
          ctaText: 'Support',
        })
        // ..
      });
  }

  // Social media sign in
  useEffect(() => {
    try {
      getRedirectResult(auth).then((response) => {
        if (response?.operationType === 'signIn') {
          setIsLoading(true);
          const usersQuery = query(usersCol, where('uid', '==', response.user.uid));
          getDocs(usersQuery).then((snap) => {
            if (snap.empty) {
              addDoc(usersCol, {
                uid: response.user.uid,
                displayName: response.user.displayName,
                avatarColor: generateColor(),
                email: response.user.email,
              }).then(() => {
                window.location.href = '/my-decisions';
              }).catch(() => {
                setIsLoading(false);
                setShowErrorModal({
                  error: true,
                  ctaText: 'Support',
                })
              })
            } else {
              window.location.href = '/my-decisions';
            }
          }).catch(() => {
            setIsLoading(false);
            setShowErrorModal({
              error: true,
              ctaText: 'Support',
            })
          });
        }
      })
    } catch (error) {
      setIsLoading(false);
      setShowErrorModal({
        error: true,
        ctaText: 'Support',
      })
    }

    const unsubscribe = onAuthStateChanged(auth, (userCurrent) => {
      // if (userCurrent) {
      //   addDoc(usersCol, {
      //     uid: userCurrent.uid,
      //     displayName: userCurrent.email.split('@')[0],
      //     avatarColor: generateColor(),
      //   });
      //   window.location.href = '/my-decisions';
      // }
      // setUser(user)
    });

    return () => {
      unsubscribe();
    }
  }, [])

  return (
    <MainContentSection maximumWidth='480px'>

      <InputModal
        showModal={showEmailModal}
        handleDialogClose={handleEmailDialogClose}
        message='Please enter in your email address:'
        ctaText='Reset email'
        secondaryText='Cancel'
        inputValue={resetEmail}
        inputChangeType='resetEmail'
        placeholderText='Enter email address'
        handleCtaClick={handleEmailResetModalClick}
        handleSecondaryButtonClick={handleEmailDialogClose}
        onInputChange={onInputChange}
      />

      <InfoModal
        showModal={showResetConfirmationModal}
        handleDialogClose={handleResetDialogClose}
        message='A password reset email was sent to the email address linked to your account. Please follow the instructions to reset your password.'
      />

      <ErrorModal
        showModal={showErrorModal.error}
        handleDialogClose={handleDialogClose}
        message={showErrorModal.message}
        ctaText={showErrorModal.ctaText}
        handleCtaClick={handleDialogCtaClick}
      />

      {
        isLoading && (
          <Grid container item justifyContent='center' pt={10}>
            <CircularProgress />
          </Grid>
        )
      }
      {
        !isLoading && (
          <SStack>
            <STextField
              label=""
              placeholder='Email'
              type='email'
              value={email.value}
              onChange={(e) => onInputChange(e, 'email')}
            />
            <STextField
              label=""
              placeholder='Password'
              type='password'
              value={password.value}
              onChange={(e) => onInputChange(e, 'password')}
              sx={{ marginTop: '24px' }}
            />
            <Grid container item xs={12} mt={3}>
              <CtaButton text='Sign In' fullWidth={true} isDisabled={!(email.isValid && password.isValid)} handleClick={signInEmailPassword} />
            </Grid>
            <Grid container item xs={12} mt={1} mb={1} justifyContent='center'>
              <Button variant='text' text='Sign In' sx={{ textTransform: 'none' }} onClick={handleForgotPasswordClick}>
                <SLinkText variant='subtitle1'>Forgot Password</SLinkText>
              </Button>
            </Grid>
            <Grid container item mb={2} justifyContent='center'>
                <Typography variant='subtitle1'>I agree to the <StyledLink href='/eula' text='End User License Agreement' />.</Typography>
            </Grid>
            <Grid container item justifyContent='center' sx={{ position: 'relative' }}>
              <SLineGrid container item />
              <Typography sx={{ color: '#A9A9A9', backgroundColor: '#F0F0F0', zIndex: '10', padding: '0 24px' }}>Sign in with</Typography>
            </Grid>
            {
              socialList.map((item, index) => (
                <Grid key={`socialListSignIn${index}`} container item xs={12} mt={3}>
                  <SocialButton
                    text={item}
                    handleClick={() => handleSocialClick(item)}
                  />
                </Grid>
              ))
            }
          </SStack>
        )
      }

    </MainContentSection>
  );
}

export default SignIn;
