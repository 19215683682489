import React from 'react';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';
import { Link, Typography } from '@mui/material';

import { MAX_CONTENT_WIDTH } from '../utils/constants';

import facebookIcon from '../static/img/footer/facebook-icon-footer.svg';
import twitterIcon from '../static/img/footer/twitter-icon-footer.svg';
import instagramIcon from '../static/img/footer/instagram-icon-footer.svg';

const SFooterGrid = styled(Grid)(({ theme }) => ({
  margin: '20px auto 40px',
  padding: '0 24px',
  maxWidth: MAX_CONTENT_WIDTH,
  '@media (max-width:700px)': {
    display: 'block',
    marginBottom: '32px',
  },
}));

const STypography = styled(Typography)(({ theme }) => ({
  color: '#A9A9A9',
  display: 'inline-block',
  '@media (max-width:700px)': {
    width: '100%',
    marginBottom: '16px',
    textAlign: 'center'
  },
}));

const SSocialStack = styled(Grid)(({ theme }) => ({
  '@media (max-width:700px)': {
    width: '100%',
    textAlign: 'center',
  },
}));

const SSocialGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: 'rgba(169, 169, 169, 0.24)',
  width: '36px',
  height: '36px',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: 'rgba(169, 169, 169, 0.50)',
  },
}));

const SLink = styled(Link)({
  display: 'inline-block',
  marginLeft: '16px',
  '&:first-of-type': {
    marginLeft: '0',
  }
});

function ResponsiveFooter() {
  const date = new Date();
  let currentYear = date.getFullYear();

  const socialList = [
    {
      link: 'https://www.facebook.com',
      icon: facebookIcon,
      alt: 'Facebook social icon'
    },
    {
      link: 'https://www.twitter.com',
      icon: twitterIcon,
      alt: 'Twitter social icon'
    },
    {
      link: 'https://www.instagram.com',
      icon: instagramIcon,
      alt: 'Instagram social icon'
    },
  ]

  return (
    <SFooterGrid container item justifyContent='space-between' alignItems='flex-end'>
      <STypography>Copyright &copy; {currentYear} Smart Decision | All Rights Reserved</STypography>
      <SSocialStack direction='row'>
        {
          socialList.map((social, i) => (
            <SLink key={`SocialFooterLink-${i}`} href={social.link} target='_blank' rel="noopener">
              <SSocialGrid container item justifyContent='center' alignItems='center'>
                <img src={social.icon} alt={social.alt} />
              </SSocialGrid>
            </SLink>
          ))
        }
      </SSocialStack>
    </SFooterGrid>
  );
}

export default ResponsiveFooter;