import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import MyDecisions from './pages/MyDecisions';
import NewDecision from './pages/NewDecision/NewDecision';
import UpdateDecision from './pages/UpdateDecision';
import Support from './pages/Support';
import Profile from './pages/Profile';
import Home from './pages/Home';
import EULA from './pages/EULA';
import Features from './pages/Features';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import ResponsiveFooter from './components/ResponsiveFooter';
import useScreenSize from './hooks/useScreenSize';

function App() {
  const screenSize = useScreenSize();
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/sign-in",
      element: <SignIn />,
    },
    {
      path: "/sign-up",
      element: <SignUp />,
    },
    {
      path: "/my-decisions",
      element: <MyDecisions />,
    },
    {
      path: "/new-decision",
      element: <NewDecision />,
    },
    {
      path: "/update-decision/:decisionId",
      element: <UpdateDecision />,
    },
    {
      path: "/support",
      element: <Support />,
    },
    {
      path: "/my-profile",
      element: <Profile />,
    },
    {
      path: '/eula',
      element: <EULA />,
    },
    {
      path: '/features',
      element: <Features />
    }
  ]);

  return (
    <div className="App" style={{ margin: '0 auto', display: 'flex', flexDirection: 'column', minHeight: `${screenSize.height}px` }}>
      <ResponsiveAppBar />
      <div style={{ flex: '1' }} >
        <RouterProvider router={router} />
      </div>
      <ResponsiveFooter />
    </div>
  );
}

export default App;
