import React from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/system'
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import CtaButton from '../../components/Buttons/CtaButton';
import SecondaryButton from '../../components/Buttons/SecondaryButton';

import DeleteIcon from '../../static/img/decision-icons/delete-x-icon.svg';

const SDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: '24px 0 0',
}));

const SCloseIcon = styled('img')(({ theme }) => ({
  width: '24px',
  height: '24px',
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    width: '30px',
    height: '30px',
  }
}));

function GeneralModal({
  showModal,
  handleDialogClose,
  message,
  secondaryText,
  handleSecondaryButtonClick,
  ctaText,
  handleCtaClick,
  buttonColor,
}) {
  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="general-dialog-title"
      open={showModal}
    >
      <SDialogTitle id="general-dialog-title" onClose={handleDialogClose} />
      <DialogContent>
        <Grid container item justifyContent='flex-end' mb={4}>
          <SCloseIcon src={DeleteIcon} alt='Close icon for dialog' onClick={handleDialogClose} />
        </Grid>
        <Typography variant='body1'>{message}</Typography>
        <Stack direction='row' justifyContent='center' mt={4}>
          <Grid mr={2}>
            <SecondaryButton text={secondaryText} color={buttonColor || 'primary'} isModal={true} handleClick={handleSecondaryButtonClick} />
          </Grid>
          <Grid>
            <CtaButton text={ctaText} color={buttonColor || 'primary'} isModal={true} handleClick={handleCtaClick} />
          </Grid>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default GeneralModal;