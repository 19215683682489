import React from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system'
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import CtaButton from '../../components/Buttons/CtaButton';

import DeleteIcon from '../../static/img/decision-icons/delete-x-icon.svg';

function ErrorModal({
  showModal,
  handleDialogClose,
  message,
  ctaText,
  handleCtaClick,
}) {
  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="customized-dialog-title"
      open={showModal}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleDialogClose} sx={{ padding: '24px 0 0' }} />
      <DialogContent>
        <Grid container item justifyContent='flex-end' sx={{ marginBottom: '16px' }}>
          <img style={{ cursor: 'pointer' }} src={DeleteIcon} alt='Close icon for dialog' onClick={handleDialogClose} />
        </Grid>
        <Typography variant='body1' sx={{ marginBottom: '32px' }}>
          {
            message ||
            'Oops! We’re sorry but an unexpected error has occurred. We’re still working out all of the kinks in the app so please be patient with us. ' +
            'We’d love it if you let us know what you were doing when you ran into this error. Thanks!'
          }
        </Typography>
        {
          ctaText && (
            <Grid container item justifyContent='center'>
              <Grid xs={8}>
                <CtaButton text={ctaText} handleClick={handleCtaClick} />
              </Grid>
            </Grid>
          )
        }
      </DialogContent>
    </Dialog>
  )
}

export default ErrorModal;