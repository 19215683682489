import React from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/system'
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';

import CtaButton from '../../components/Buttons/CtaButton';
import SecondaryButton from '../../components/Buttons/SecondaryButton';

import DeleteIcon from '../../static/img/decision-icons/delete-x-icon.svg';

function InputModal({
  showModal,
  handleDialogClose,
  message,
  secondaryText,
  handleSecondaryButtonClick,
  ctaText,
  handleCtaClick,
  buttonColor,
  inputValue,
  placeholderText,
  inputChangeType,
  onInputChange,
}) {
  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="customized-dialog-title"
      open={showModal}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleDialogClose} sx={{ padding: '24px 0 0' }} />
      <DialogContent>
        <Grid container item justifyContent='flex-end' sx={{ marginBottom: '16px' }}>
          <img style={{ cursor: 'pointer' }} src={DeleteIcon} alt='Close icon for dialog' onClick={handleDialogClose} />
        </Grid>
        <Typography variant='body1' sx={{ marginBottom: '32px' }}>
          {message}
        </Typography>
        <TextField
          label=""
          placeholder={placeholderText}
          type='text'
          value={inputValue.value}
          onChange={(e) => onInputChange(e, inputChangeType)}
          sx={{ width: '100%' }}
        />
        <Stack direction='row' mt={3} justifyContent='center'>
          <Grid mr={2}>
            <SecondaryButton text={secondaryText} color={buttonColor || 'primary'} handleClick={handleSecondaryButtonClick} />
          </Grid>
          <Grid xs={8}>
            <CtaButton text={ctaText} isDisabled={!inputValue.isValid} color={buttonColor || 'primary'} handleClick={handleCtaClick} />
          </Grid>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default InputModal;