import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CtaButton from '../components/Buttons/CtaButton'
import { styled } from '@mui/system'
import { Typography } from '@mui/material';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { SNACKBAR_INTERVAL } from '../utils/constants';

import MainContentSection from '../components/Sections/MainContentSection';

import validateEmailAddress from '../utils/inputValidation/validateEmailAddress';

import { getFirebase } from '../firebase'
import {
  onAuthStateChanged,
} from '@firebase/auth';

const STitle = styled(Typography)({
  marginBottom: '36px',
});

const SSectionTitle = styled(Typography)(({ theme }) => ({
  marginLeft: '0',
  marginBottom: '12px',
  [theme.breakpoints.up('md')]: {
    marginLeft: '24px',
    marginBottom: '24px',
  }
}));

const STextarea = styled(TextareaAutosize)({
  borderRadius: '5px',
  fontSize: '1rem',
  fontFamily: 'Inter, Arial, sans-serif',
  padding: '16px 16px',
  borderColor: '#ADADAD',
  '&:focus': {
    borderColor: '#410CDA',
  },
  '&:focus-visible': {
    outline: '#410CDA auto 1px',
  },
});

const STextField = styled(TextField)({
  width: '100%',
  backgroundColor: '#FFF',
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#410CDA',
    },
  },
});

const SSupportStack = styled(Stack)(({ theme }) => ({
  width: '50%',
  marginBottom: '72px',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}));

function Support() {
  const { auth } = getFirebase();
  const [supportMessage, setSupportMessage] = useState({ value: '', isValid: false });
  const [supportEmail, setSupportEmail] = useState({ value: '', isValid: false });
  const [openSupportSnackbar, setOpenSupportSnackbar] = useState(false);
  const [user, setUser] = useState(auth.currentUser);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        setUser(user);
      }
    });

    return () => {
      unsubscribe();
    }
  }, [])

  const onInputChange = (e, type) => {
    const inputValue = e.target.value;
    let isValid = false;

    if (type === 'supportMessage') {
      if (inputValue) {
        isValid = true;
      }
      setSupportMessage({ value: inputValue, isValid: isValid });
    } else if (type === 'supportEmail') {
      if (validateEmailAddress(inputValue)) {
        isValid = true;
      }
      setSupportEmail({ value: inputValue, isValid: isValid });
    }
  }

  const handleSnackBarClose = (event, reason, type) => {
    if (reason === 'clickaway') {
      return;
    }

    if (type === 'support') {
      setOpenSupportSnackbar(false);
    }
  }

  const supportSnackBarAction = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={(event, reason) => handleSnackBarClose(event, reason, 'email')}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const handleSupportClick = () => {
    setOpenSupportSnackbar(true);
    window.location.href = `mailto:yourmail@domain.com?subject=${encodeURIComponent('Smart Decision Support')}&body=${encodeURIComponent(supportMessage.value)}`
    setSupportEmail({ value: '', isValid: false });
    setSupportMessage({ value: '', isValid: false });
  }

  return (
    <MainContentSection>
      <Grid container justifyContent='center'>
        <iframe 
          src="https://docs.google.com/forms/d/e/1FAIpQLScO8iy2CrDTnAnXhg_cvw1U5jukXZDhK8nrTVreU1epMylEnQ/viewform?embedded=true" 
          width="640" 
          height="824"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </Grid>
      {/* <Snackbar
        open={openSupportSnackbar}
        autoHideDuration={SNACKBAR_INTERVAL}
        onClose={(event, reason) => handleSnackBarClose(event, reason, 'support')}
        message='Email sent successfully!'
        action={supportSnackBarAction}
      />
      <Grid container item justifyContent='space-between'>
        <STitle variant='h1'>Support</STitle>
      </Grid>
      <Grid container item>
        <SSupportStack>
          <SSectionTitle variant='h2'>Use this form to contact us</SSectionTitle>
          {
            !user && (
              <Box mb={2}>
                <STextField
                  label=""
                  placeholder='Enter email address here'
                  type='text'
                  value={supportEmail.value}
                  onChange={(e) => onInputChange(e, 'supportEmail')}
                />
              </Box>
            )
          }

          <STextarea
            minRows={12}
            placeholder='Enter your support issue here.'
            value={supportMessage.value}
            onChange={(e) => onInputChange(e, 'supportMessage')}
          />
          <Grid container item sx={{ width: { xs: '100%', sm: '125px' } }} mt={2}>
            <CtaButton
              isDisabled={
                user ?
                  !supportMessage.isValid :
                  !(supportMessage.isValid && supportEmail.isValid)
              }
              text='Submit'
              handleClick={handleSupportClick}
            />
          </Grid>
        </SSupportStack>
      </Grid> */}
    </MainContentSection>
  );
}

export default Support;
