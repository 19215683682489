import { createTheme } from '@mui/material/styles';

const fontList = "'Inter', 'Arial', sans-serif";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 960,
      xl: 1300,
      // sm: 600,
      // md: 900,
      // lg: 1200,
      // xl: 1536,
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: "'Inter', 'Arial', sans-serif",
  },
  palette: {
    primary: {
      main: '#410CDA',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FBBC05',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#FF0000',
      contrastText: '#FFFFFF',
    },
    text: {
      main: '#7E7E7E',
    },
    alternateText: {
      main: '#000000',
    },
    navigationText: {
      main: '#000000',
    },
    background: {
      main: '#F0F0F0',
      primary: '#410CDA0D',
      dark: '#E7E7E7',
    },
    link: {
      main: '#1877F2',
    }
  },
});

// main page titles
theme.typography.h1 = {
  fontWeight: '700',
  fontSize: '1.4rem',
  color: theme.palette.text.main,
  fontFamily: fontList,
  [theme.breakpoints.up('sm')]: {
    fontSize: '2rem',
  },
}

// main page titles
theme.typography.h2 = {
  fontSize: '1rem',
  fontWeight: '700',
  color: theme.palette.text.main,
  fontFamily: fontList,
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.5rem',
  },
}

// homepage image title text
theme.typography.h3 = {
  fontSize: '1.2rem',
  fontWeight: '700',
  color: theme.palette.text.main,
  fontFamily: fontList,
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.75rem',
  },
}

// main navigation link text
theme.typography.h4 = {
  fontSize: '0.875rem',
  fontWeight: '400',
  color: theme.palette.navigationText.main,
  fontFamily: fontList,
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.875rem',
  },
}

// Home page and Features title
theme.typography.h5 = {
  fontSize: '2.4rem',
  fontWeight: '800',
  color: theme.palette.navigationText.main,
  fontFamily: fontList,
  [theme.breakpoints.up('sm')]: {
    fontSize: '3rem',
  },
}

// Smaller setting/input heading
theme.typography.h6 = {
  fontSize: '0.875rem',
  fontWeight: '500',
  color: theme.palette.text.main,
  fontFamily: fontList,
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.25rem',
  },
}

// cta button text
theme.typography.button = {
  fontWeight: '700',
  fontSize: '1rem',
  color: theme.palette.primary.contrastText,
  fontFamily: fontList,
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.2rem',
  },
}

// Default body text
theme.typography.body1 = {
  fontSize: '1rem',
  fontWeight: '400',
  color: theme.palette.text.main,
  lineHeight: '1.2',
  fontFamily: fontList,
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.25rem',
  },
}

// Results bar label
theme.typography.caption = {
  fontSize: '1.25rem',
  fontWeight: '500',
  color: theme.palette.text.main,
  fontFamily: fontList,
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.5rem',
  },
}

// Forgot Password link
theme.typography.subtitle1 = {
  fontSize: '0.875rem',
  fontWeight: '400',
  color: theme.palette.text.main,
  fontFamily: fontList,
  [theme.breakpoints.up('sm')]: {
    fontSize: '1rem',
  },
}

export default theme;