import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import Prompt from '../../components/Prompt';

import MainContentSection from '../../components/Sections/MainContentSection';
import GeneralModal from '../../components/Modals/GeneralModal';

import useIsMobile from '../../hooks/useIsMobile';

import QuestionView from './QuestionView';
import ScoringView from './ScoringView';
import ResultsView from './ResultsView';

import {
  onAuthStateChanged,
} from '@firebase/auth';

import { getFirebase } from "../../firebase";
import { collection, doc, setDoc, addDoc } from 'firebase/firestore';

function NewDecision() {
  const { firestore, auth } = getFirebase();
  const [decision, setDecision] = useState({
    uid: '',
    question: '',
    options: [''],
    criteria: [{ text: '', weight: 0 }],
    scores: [],
  });

  const [currentView, setCurrentView] = useState('questionView');
  const [showViewModal, setShowViewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDecisionSaved, setIsDecisionSaved] = useState(false);
  const [decisionId, setDecisionId] = useState(null);
  const [decisionDoc, setDecisionDoc] = useState(null);
  const decisionsCol = collection(firestore, 'decisions');

  const isMobile = useIsMobile();

  useEffect(() => {
    const currentDecision = JSON.parse(window.sessionStorage.getItem("currentDecision"));
    if (currentDecision) {
      setDecision(JSON.parse(window.sessionStorage.getItem("currentDecision")));
    }

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setDecision({ ...decision, uid: user.uid });
        setIsLoading(false);
      } else {
        window.location.href = '/sign-in';
      }
    });

    return () => {
      unsubscribe();
    }
  }, []);

  useEffect(() => {
    if (decisionId) {
      setDecisionDoc(doc(decisionsCol, decisionId));
    }
  }, [decisionId]);

  const onInputChange = (e, type, i, i2, scoreValue) => {
    if (type === 'question') {
      setDecision({
        ...decision,
        question: e.target.value
      });
    } else if (type === 'options') {
      const newArray = [...decision.options].map((item, index) => {
        if (i !== index) {
          return item;
        }
        return e.target.value;
      })
      setDecision({
        ...decision,
        options: newArray
      });
    } else if (type === 'criteriaText') {
      const newArray = [...decision.criteria].map((item, index) => {
        if (i !== index) {
          return item;
        }
        return { text: e.target.value, weight: item.weight };
      })
      setDecision({
        ...decision,
        criteria: newArray
      })
    } else if (type === 'criteriaWeight') {
      const newArray = [...decision.criteria].map((item, index) => {
        if (i !== index) {
          return item;
        }
        return { text: item.text, weight: e.target.value }
      })
      setDecision({
        ...decision,
        criteria: newArray
      })
    } else if (type === 'score') {
      let scoreArray = [...decision.scores];
      scoreArray[i][i2] = scoreValue;

      setDecision({
        ...decision,
        scores: scoreArray
      })
    }
  }

  const handleScoreClick = () => {
    let newArray = [];

    // Add all zeros to scores array
    const zeros = (dimensions) => {
      let array = [];

      for (let i = 0; i < dimensions[0]; ++i) {
        array.push(dimensions.length === 1 ? 0 : zeros(dimensions.slice(1)));
      }

      return array;
    }

    // Fill in zeros to added options or criteria
    const fillInZeros = (currentOptions, currentCriteria) => {
      let array = [...decision.scores];

      for (let optIndex = 0; optIndex < currentOptions.length; optIndex++) {
        for (let critIndex = 0; critIndex < currentCriteria.length; critIndex++) {
          if (array[optIndex] === undefined) {
            array.push([])
          }
          if (array[optIndex][critIndex] === undefined) {
            array[optIndex].push(0);
          }
        }
      }

      return array;
    }

    if (decision.scores.length === 0) {
      // initialize this double array
      newArray = zeros([decision.options.length, decision.criteria.length]);
    } else {
      newArray = fillInZeros(decision.options, decision.criteria);
    }

    setDecision({
      ...decision,
      scores: newArray
    });
    window.sessionStorage.setItem("currentDecision", JSON.stringify({ ...decision, scores: newArray }));

    setCurrentView('scoringView');
  }

  const handleViewResultsClick = () => {
    window.sessionStorage.setItem("currentDecision", JSON.stringify({ ...decision }));

    if (isDecisionSaved) {
      // update instead
      setDoc(decisionDoc, { uid: decision.uid, question: decision.question, options: decision.options, criteria: decision.criteria, scores: JSON.stringify(decision.scores) }, { merge: true })
        .then(() => {
          setCurrentView('resultsView');
        })
        .catch((error) => {
          console.error('Error updating new decision: ', error);
        });
    } else {
      addDoc(decisionsCol, { uid: decision.uid, question: decision.question, options: decision.options, criteria: decision.criteria, scores: JSON.stringify(decision.scores) })
        .then((docRef) => {
          setIsDecisionSaved(true);
          setDecisionId(docRef.id);
          setCurrentView('resultsView');
        })
        .catch((error) => {
          console.error('Error saving new decision: ', error);
        });
    }
  }

  const handleBackClick = () => {
    if (currentView === 'questionView') {
      setShowViewModal(true);
    } else if (currentView === 'scoringView') {
      setCurrentView('questionView')
    } else if (currentView === 'resultsView') {
      setCurrentView('scoringView')
    }
  }

  const createOption = () => {
    setDecision({
      ...decision,
      options: [
        ...decision.options,
        ''
      ]
    });
  }

  const deleteOption = (e, idx) => {
    let filteredDecisions = [...decision.options].filter((item, index) => idx !== index);
    if (filteredDecisions.length === 0) { filteredDecisions = [''] }
    setDecision({
      ...decision,
      options: [
        ...filteredDecisions,
      ]
    });
  }

  const createCriteria = () => {
    setDecision({
      ...decision,
      criteria: [
        ...decision.criteria,
        { text: '', weight: 0 }
      ]
    })
  }

  const deleteCriteria = (e, idx) => {
    let filteredDecisions = [...decision.criteria].filter((item, index) => idx !== index)
    if (filteredDecisions.length === 0) { filteredDecisions = [{ text: '', weight: 0 }] }
    setDecision({
      ...decision,
      criteria: [
        ...filteredDecisions,
      ]
    })
  }

  const handleDialogClose = () => {
    setShowViewModal(false);
  }

  const navigatePage = (link) => {
    window.location.href = link;
  }

  return (
    <>
      <MainContentSection>
        {
          !auth?.currentUser && (
            <Grid container item justifyContent='center' pt={10}>
              <CircularProgress />
            </Grid>
          )
        }
        {
          auth?.currentUser && (
            <>
              <GeneralModal
                showModal={showViewModal}
                handleDialogClose={handleDialogClose}
                message={'Are you sure you want to leave this page? Your current decision will not be saved.'}
                secondaryText='Leave Page'
                handleSecondaryButtonClick={() => navigatePage('/my-decisions')}
                ctaText='Stay on Page'
                handleCtaClick={handleDialogClose}
              />

              <Prompt
                hasUnsavedChanges={currentView !== 'resultsView'}
                message='Are you sure you want to leave?'
              />

              {
                currentView === 'questionView' && (
                  <QuestionView
                    isMobile={isMobile}
                    decision={decision}
                    isLoading={isLoading}
                    handleScoreClick={handleScoreClick}
                    handleBackClick={handleBackClick}
                    onInputChange={onInputChange}
                    createOption={createOption}
                    deleteOption={deleteOption}
                    createCriteria={createCriteria}
                    deleteCriteria={deleteCriteria}
                  />
                )
              }
              {
                currentView === 'scoringView' && (
                  <ScoringView
                    isMobile={isMobile}
                    decision={decision}
                    isLoading={isLoading}
                    handleViewResultsClick={handleViewResultsClick}
                    handleBackClick={handleBackClick}
                    onInputChange={onInputChange}
                    createOption={createOption}
                    deleteOption={deleteOption}
                    createCriteria={createCriteria}
                    deleteCriteria={deleteCriteria}
                  />
                )
              }
              {
                currentView === 'resultsView' && (
                  <ResultsView
                    isMobile={isMobile}
                    decision={decision}
                    isLoading={isLoading}
                    handleViewResultsClick={handleViewResultsClick}
                    handleBackClick={handleBackClick}
                    onInputChange={onInputChange}
                    createOption={createOption}
                    deleteOption={deleteOption}
                    createCriteria={createCriteria}
                    deleteCriteria={deleteCriteria}
                  />
                )
              }
            </>
          )
        }
      </MainContentSection>
    </>
  );
}

export default NewDecision;