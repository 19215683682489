import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CtaButton from '../Buttons/CtaButton';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';

const SButtonGrid = styled(Button)(({ theme }) => ({
  width: '192px',
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  }
}));

function TitleCtaHeading({ title, ctaText, isDisabled = false, ctaClick }) {
  return (
    <Grid container item justifyContent='space-between' alignItems>
      <Typography variant='h1'>{title}</Typography>
      <SButtonGrid container item xs={4} sx={{ padding: '0' }}>
        <CtaButton isDisabled={isDisabled} text={ctaText} handleClick={ctaClick} />
      </SButtonGrid>
    </Grid>
  )
}

export default TitleCtaHeading;