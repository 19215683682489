import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';

import Badge from '@mui/material/Badge';
import CheckIcon from '@mui/icons-material/Check';
import MainContentSection from '../components/Sections/MainContentSection';
import CtaButton from '../components/Buttons/CtaButton';


const STitleMain = styled('span')(({ theme }) => ({
  fontSize: `calc(${theme.typography.h5.fontSize} * 0.8)`,
  display: 'block',
  textAlign: 'center',
}));

const STitlePrimary = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const STItleSecondary = styled('span')(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

const STitleGrid = styled(Grid)({
  textAlign: 'center',
});

const SPricingCardTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.alternateText.main,
}));

const SPricingCardTitleSpan = styled('span')(({ theme, text }) => ({
  color: text === 'Free' ? theme.palette.primary.main : theme.palette.secondary.main
}));

const SPricingCardGrid = styled(Grid)(({ theme, count }) => ({
  width: `calc(100% / ${count})`,
  '@media (max-width:680px)': {
    width: '100%',
    marginBottom: '40px',
  },
}));

const Features = () => {

  const pricingCardsData = [
    {
      title: 'Free',
      cardItems: [
        {
          isCheckPrimary: true,
          text: 'Create & Update Decisions'
        },
        {
          isCheckPrimary: true,
          text: 'Duplicate & Delete Decisions'
        },
        {
          isCheckPrimary: true,
          text: 'Save up to 5 Decisions'
        },
        {
          isCheckPrimary: true,
          text: 'Enter up to 5 Options'
        },
        {
          isCheckPrimary: true,
          text: 'Enter up to 5 Decision Criteria'
        },
      ]
    },
    {
      title: 'Premium',
      cardItems: [
        {
          isCheckPrimary: true,
          text: 'Create & Update Decisions'
        },
        {
          isCheckPrimary: true,
          text: 'Duplicate & Delete Decisions'
        },
        {
          isCheckPrimary: false,
          text: <span>Save up to <b>100</b> Decisions</span>,
        },
        {
          isCheckPrimary: false,
          text: <span>Enter up to <b>20</b> Options</span>,
        },
        {
          isCheckPrimary: false,
          text: <span>Enter up to <b>20</b> Decision Criteria</span>,
        },
        {
          isCheckPrimary: false,
          text: <span><b>Dark Mode</b></span>,
        },
      ]
    },
  ]

  const PricingCard = ({ card, totalCards }) => {
    const { title, cardItems } = card

    return (
      <SPricingCardGrid container item count={totalCards} direction='column' justifyContent='flex-start' alignItems='center'>
        {
          title === 'Premium' && (
            <Badge
              badgeContent='Coming Soon!'
              color='secondary'
            >
              <SPricingCardTitle sx={{ display: 'inline-block' }} variant='h3' mb={1.5}><SPricingCardTitleSpan text={title}>{title}</SPricingCardTitleSpan> Version</SPricingCardTitle>
            </Badge>
          )
        }
        {
          title === 'Free' && (
            <SPricingCardTitle sx={{ display: 'inline-block' }} variant='h3' mb={1.5}><SPricingCardTitleSpan text={title}>{title}</SPricingCardTitleSpan> Version</SPricingCardTitle>
          )
        }
        <Grid item>
          {
            cardItems.map((item, index) => (
              <Stack key={`featureCardItem-${index}`} direction='row' mb={1}>
                <CheckIcon
                  sx={{
                    color: (theme) => item.isCheckPrimary ? theme.palette.primary.main : theme.palette.secondary.main,
                    marginRight: '8px',
                    width: '24px',
                  }} />
                <Typography variant='body1' sx={{ color: (theme) => theme.palette.alternateText.main }}>{item.text}</Typography>
              </Stack>
            ))
          }
        </Grid>
      </SPricingCardGrid>
    )
  }

  return (
    <MainContentSection>
      <STitleGrid container item mb={4} justifyContent='center'>
        <Typography variant='h5' component='h1' mb={4}><STitleMain>Take your decisions to the next level with</STitleMain> <STitlePrimary>Smart Decision</STitlePrimary> <STItleSecondary>Premium</STItleSecondary></Typography>
      </STitleGrid>
      <Grid container item justifyContent='center'>
        {
          pricingCardsData.map((card, index) => (
            <PricingCard key={`featurePricingCard-${index}`} card={card} totalCards={pricingCardsData.length} />
          ))
        }
        <Grid container item justifyContent='center' mb={2} mt={{ xs: 2, md: 5 }}>
          <Typography variant='h3' sx={{ color: (theme) => theme.palette.alternateText.main }}>Go Premium Today!</Typography>
        </Grid>
        <Grid item justifyContent='flex-start'>
          <Grid container item justifyContent='center' alignItems='center' flexWrap='nowrap' mb={2}>
            <Grid item>
              <CtaButton text='$2.99 per Month' color='secondary' isDisabled handleClick={() => console.log('2.99')} />
            </Grid>
            <Typography variant='body1' ml={2} sx={{ color: (theme) => theme.palette.alternateText.main }}>1-Time Annual Payment</Typography>
          </Grid>
          <Grid container item alignItems='center' flexWrap='nowrap'>
            <Grid item>
              <CtaButton text='$3.99 per Month' color='secondary' isDisabled handleClick={() => console.log('3.99')} />
            </Grid>
            <Typography variant='body1' ml={2} sx={{ color: (theme) => theme.palette.alternateText.main }}>Monthly Payment</Typography>
          </Grid>
        </Grid>
      </Grid>
    </MainContentSection >
  )
}

export default Features;