import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import GeneralModal from '../components/Modals/GeneralModal';
import MainContentSection from '../components/Sections/MainContentSection';
import TitleCtaHeading from '../components/Sections/TitleCtaHeading';

import { 
  FREE_TIER_DECISION_LIMIT,
} from '../utils/constants'

import { getFirebase } from "../firebase";
import { getDocs, collection, query, where, addDoc, deleteDoc, onSnapshot, doc } from 'firebase/firestore';
import {
  onAuthStateChanged,
} from '@firebase/auth';

import DeleteIcon from '../static/img/decision-icons/trashcan-icon.svg';
import DuplicateIcon from '../static/img/decision-icons/duplicate-icon.svg';

const SDecisionTypography = styled(Typography)({
  fontSize: '1.2rem',
  color: '#7E7E7E',
  cursor: 'pointer',
})

const SDecisionsStack = styled(Stack)({
  width: '100%',
  marginTop: '24px'
})

const SDecisionGrid = styled(Grid)(({ theme }) => ({
  padding: '8px 16px',
  border: 'solid 1px transparent',
  borderRadius: '5px',
  transition: 'border 0.3s',
  '&:hover': {
    border: 'solid 1px #410CDA',
  },
  [theme.breakpoints.up('md')]: {
    padding: '16px 32px',
  },
}));

const SEmpty = styled(Typography)(({ theme }) => ({
  color: '#7E7E7E',
  fontSize: '18px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '24px',
  }
}));

const SEmptyStack = styled(Stack)({
  textAlign: 'center',
});

const SActionIcon = styled('img')(({ theme }) => ({
  height: '20px',
  width: '20px',
  [theme.breakpoints.up('md')]: {
    height: '30px',
    width: '30px',
  }
}));

function MyDecisions() {
  const { firestore, auth } = getFirebase();
  const [hoverState, setHoverState] = useState({ isHovered: false, currentIndex: 0 });
  const decisionsCol = collection(firestore, 'decisions');
  const [showDeleteModal, setShowDeleteModal] = useState({ status: false, id: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [showUpgradeModalDecisions, setShowUpgradeModalDecisions] = useState(false)

  const [decisions, setDecisions] = useState([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const decisionsQuery = query(decisionsCol, where('uid', '==', user.uid));
        const snapshot = await getDocs(decisionsQuery);
        const userDecisions = snapshot.docs.map(d => {
          return { ...d.data(), id: d.id }
        });
        setDecisions(userDecisions);
        setIsLoading(false);
      } else {
        window.location.href = '/sign-in';
      }
    });

    onSnapshot(decisionsCol, snapshot => {
      setIsLoading(true);
      const currentDecisionsList = snapshot.docs.filter(d => d.data().uid === auth.currentUser.uid).map(d => {
        return { ...d.data(), id: d.id }
      })
      if (currentDecisionsList) {
        setDecisions(currentDecisionsList);
        setIsLoading(false);
      }
    });

    return () => {
      unsubscribe();
    }
  }, []);

  const handleMouseOver = (index) => {
    setHoverState({ isHovered: true, currentIndex: index })
  }

  const handleMouseOut = () => {
    setHoverState({ ...hoverState, isHovered: false })
  }

  const handleDelete = (id, isHovered) => {
    if (isHovered) {
      setShowDeleteModal({ status: true, id: id })
    }
  }

  const handleDuplicate = (item, isHovered) => {
    if (isHovered) {
      if (decisions.length < FREE_TIER_DECISION_LIMIT) {
        addDoc(decisionsCol, { ...item, question: `${item.question} - COPY` });
      } else {
        setShowUpgradeModalDecisions(true);
      }
    }
  }

  const handleNewDecisionClick = () => {
    if (decisions.length < FREE_TIER_DECISION_LIMIT) {
      window.location.href = '/new-decision';
    } else {
      setShowUpgradeModalDecisions(true);
    }
  }

  const handleDeleteDialogClose = () => {
    setShowDeleteModal({ status: false, id: '' });
  }

  const handleDeleteDialogConfirm = () => {
    let document = doc(firestore, `decisions/${showDeleteModal.id}`);
    deleteDoc(document);
    setShowDeleteModal({ status: false, id: '' });
  }

  const navigatePage = (link) => {
    window.location.href = link;
  }

  const handleUpgradeModalDecisionsClose = () => {
    setShowUpgradeModalDecisions(false)
  }

  return (
    <>
      <MainContentSection>
        {
          !auth?.currentUser && (
            <Grid container item justifyContent='center' pt={10}>
              <CircularProgress />
            </Grid>
          )
        }
        {
          auth?.currentUser && (
            <>
              <GeneralModal
                showModal={showDeleteModal.status}
                handleDialogClose={handleDeleteDialogClose}
                message={'Are you sure you want to delete this decision? Deleting this decision cannot be reversed. The data will be deleted and cannot be restored.'}
                secondaryText='Cancel'
                handleSecondaryButtonClick={handleDeleteDialogClose}
                ctaText='Delete Decision'
                handleCtaClick={handleDeleteDialogConfirm}
                buttonColor='error'
              />

              {
                !isLoading && (
                  <>
                    <GeneralModal
                      showModal={showUpgradeModalDecisions}
                      handleDialogClose={handleUpgradeModalDecisionsClose}
                      message={`You cannot create more than ${FREE_TIER_DECISION_LIMIT} decisions on the free tier. Please upgrade to add more decisions.`}
                      secondaryText='Cancel'
                      handleSecondaryButtonClick={handleUpgradeModalDecisionsClose}
                      ctaText='Upgrade'
                      handleCtaClick={() => navigatePage('/features')}
                    />
                    <TitleCtaHeading
                      title='My Decisions'
                      ctaText='New Decision'
                      // isDisabled={decisions.length >= FREE_TIER_DECISION_LIMIT}
                      ctaClick={handleNewDecisionClick}
                    />
                  </>
                )
              }
              <SDecisionsStack sx={{ width: '100%' }}>
                {
                  isLoading && (
                    <Grid container item justifyContent='center' pt={10}>
                      <CircularProgress />
                    </Grid>
                  )
                }
                {
                  (!isLoading && decisions.length > 0) && decisions.map((decision, index) => (
                    <SDecisionGrid
                      key={`decisionGrid-${index}`}
                      container
                      item
                      justifyContent='space-between'
                      alignItems='center'
                      xs={12}
                      onMouseOver={() => handleMouseOver(index)}
                      onMouseOut={() => handleMouseOut()}
                    >
                      <SDecisionTypography onClick={() => { window.location.href = `/update-decision/${decision.id}` }}>{decision.question}</SDecisionTypography>
                      {
                        <Grid>
                          {/* {
                            decisions.length < FREE_TIER_DECISION_LIMIT && ( */}
                              <SActionIcon
                                src={DuplicateIcon}
                                alt='Duplicate icon to duplicate decision'
                                style={{ 
                                  marginRight: '10px',
                                  cursor: (hoverState.isHovered && hoverState.currentIndex === index) ? 'pointer' : 'auto',
                                  opacity: (hoverState.isHovered && hoverState.currentIndex === index) ? '1' : '0',
                                }}
                                onClick={() => handleDuplicate(decision, (hoverState.isHovered && hoverState.currentIndex === index))}
                              />
                            {/* )
                          } */}
                          <SActionIcon
                            src={DeleteIcon}
                            alt='Trashcan icon to delete decision'
                            style={{
                              cursor: (hoverState.isHovered && hoverState.currentIndex === index) ? 'pointer' : 'auto',
                              opacity: (hoverState.isHovered && hoverState.currentIndex === index) ? '1' : '0',
                            }}
                            onClick={() => handleDelete(decision.id, (hoverState.isHovered && hoverState.currentIndex === index))}
                          />
                        </Grid>
                      }
                    </SDecisionGrid>
                  ))
                }
                {
                  (!isLoading && decisions.length === 0) && (
                    <Grid container item justifyContent='center' mt={{ xs: 8, sm: 10 }}>
                      <SEmptyStack>
                        <SEmpty variant='body1'>Looks like the list is empty.</SEmpty>
                        <SEmpty variant='body1'>Please create a new decision.</SEmpty>
                      </SEmptyStack>
                    </Grid>
                  )
                }
              </SDecisionsStack>
            </>
          )
        }
      </MainContentSection>
    </>
  );
}

export default MyDecisions;
