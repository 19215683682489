import React from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';

import { MAX_CONTENT_WIDTH } from '../../utils/constants';

const SGrid = styled(Grid)(({ theme, maximumwidth, isFullWidth, isHome }) => ({
  padding: isHome ? '0' : '0 24px',
  margin: '64px auto',
  maxWidth: maximumwidth ? maximumwidth : MAX_CONTENT_WIDTH,
  ...(isHome && { maxWidth: 'none' }),
  ...(isFullWidth && { justifyContent: 'center' }),
  [theme.breakpoints.up('md')]: {
    margin: '80px auto 64px',
  }
}));

function MainContentSection({ children, maximumWidth, page }) {
  return (
    <SGrid container item maximumwidth={maximumWidth} isHome={page === 'home'} isFullWidth={page === 'home'}>
      {children}
    </SGrid>
  )
}

export default MainContentSection;