import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';

import MainContentSection from '../components/Sections/MainContentSection';
import CtaButton from '../components/Buttons/CtaButton';

import { MAX_CONTENT_WIDTH } from '../utils/constants';

import step1and2 from '../static/img/home/step1and2.svg';
import step3and4 from '../static/img/home/step3and4.svg';
import step5 from '../static/img/home/step5.svg';
import step6 from '../static/img/home/step6.svg';

import AutorenewIcon from '@mui/icons-material/Autorenew';

const STitleGrid = styled(Grid)({
  maxWidth: MAX_CONTENT_WIDTH,
  textAlign: 'center',
  paddingLeft: '24px',
  paddingRight: '24px',
});

const STitleSpan = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const SSubtitleSpan = styled('span')(({ theme }) => ({
  fontWeight: '700',
  color: theme.palette.alternateText.main,
}));

const SBelowTitleQuestion = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  fontWeight: '900',
}));

const SBelowTitleQuestionsGrid = styled(Grid)(({ theme }) => ({
  position: 'relative',

  '&:before': {
    content: '""',
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    background: 'linear-gradient(to bottom, transparent 0%, white 100%)',
  }
}));

const SDirectionsImage = styled('img')(({ theme }) => ({
  width: '70%',
  maxWidth: `calc(${MAX_CONTENT_WIDTH} * 0.7)`,
  '@media (max-width: 600px)': {
    width: '100%',
    maxWidth: 'none'
  }
}));

const SDirectionsImageGrid = styled(Grid)(({ theme, isFullWidthBackground }) => ({
  width: '100%',
  padding: '48px 24px',
  ...(isFullWidthBackground && { backgroundColor: theme.palette.background.primary }),
}));

const SAutorenewIcon = styled(AutorenewIcon)(({ theme }) => ({
  color: theme.palette.text.main,
}));

function Home() {
  const belowTitleQuestions = [
    'What career should I pursue?',
    'What school should I apply to?',
    'What car should I buy?',
    'What city should I move to?',
    'Who should I date?',
    'What phone should I buy?',
  ]

  const handleCtaClick = () => {
    window.location.href = '/sign-up';
  }

  return (
    <MainContentSection page='home'>
      <STitleGrid container item justifyContent='center' mb={8}>
        <Typography variant='h5' component='h1' mb={1}>Make <STitleSpan>intelligent</STitleSpan> decisions</Typography>
        <Typography variant='body1' component='h2'>A powerful <SSubtitleSpan>step-by-step</SSubtitleSpan> process to making smart decisions, big and small</Typography>
        <SBelowTitleQuestionsGrid mt={4}>
          {
            belowTitleQuestions.map((question, index) => (
              <SBelowTitleQuestion variant='h3' color='alternateText.main'>{question}</SBelowTitleQuestion>
            ))
          }
        </SBelowTitleQuestionsGrid>
      </STitleGrid>
      <SDirectionsImageGrid container item justifyContent='center'>
        <SDirectionsImage src={step1and2} alt='Image of step 1 and step 2 of the Smart Decision process' />
      </SDirectionsImageGrid>
      <SDirectionsImageGrid container item justifyContent='center' isFullWidthBackground>
        <SDirectionsImage src={step3and4} alt='Image of step 3 and 4 of the Smart Decision process' isFullWidthBackground />
      </SDirectionsImageGrid>
      <SDirectionsImageGrid container item justifyContent='center' mb={-12}>
        <SDirectionsImage src={step5} alt='Image of step 4 of the Smart Decision process' />
      </SDirectionsImageGrid>
      <SDirectionsImageGrid container item justifyContent='center' isFullWidthBackground>
        <SDirectionsImage src={step6} alt='Image of step 5 of the Smart Decision process' isFullWidthBackground />
        <Grid container item alignItems='center' justifyContent='center' mt={2}>
          <SAutorenewIcon />
          <Typography variant='body1' ml={1} sx={{ display: 'inline-block' }}>Go back as many times as you want to make changes</Typography>
        </Grid>
      </SDirectionsImageGrid>
      <Grid container item justifyContent='center' mt={6} style={{ maxWidth: MAX_CONTENT_WIDTH, paddingLeft: '24px', paddingRight: '24px', }}>
        <Typography variant='h3' mb={4} sx={{ width: '100%', textAlign: 'center' }}>Start making smarter decisions today!</Typography>
        <Grid>
          <CtaButton text='Sign Up for Free' handleClick={handleCtaClick} />
        </Grid>
      </Grid>
    </MainContentSection>
  );
}

export default Home;